import React, { useState, useEffect } from 'react';
import {
    Box, Button, Card, CardContent, Grid, TextField, CardHeader, Divider,
    Dialog, DialogTitle, IconButton, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getsingleOrderActionNew, OrderUserCashback, SettleToPartner, } from 'src/store/actions/ordersAction';
import { toast } from 'react-toastify'
import isEmpty from 'src/store/validations/is-empty';
// import InfoIcon from '@mui/icons-material/Info';
import { Step, StepLabel, Stepper, Typography, Paper, CircularProgress } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';

const EditCmpltdOrderStatusHistory = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const classes = useStyles();
    const location = useLocation();
    const { orderId } = location.state || {};
    const Orders = useSelector(state => state?.orders?.singleorder?.all);
    const order_Id = Orders?.Order_Id || null;
    const [loading, setLoading] = useState(false);

    //dispatch actions
    useEffect(() => {
        if (!isEmpty(orderId)) {
            const formData = {
                "Records_Filter": "FILTER",
                "Order_Id": orderId
            };
            dispatch(getsingleOrderActionNew(formData));
        }
    }, []);

    //for billing address......
    const address = Orders?.User_Billing_Address_Full;
    let billAddress = null;
    if (address) {
        try {
            billAddress = JSON.parse(address);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    //for shipping address......
    const shippingAddress = Orders?.User_Shipping_Address_Full;
    let shipAddress = null;
    if (shippingAddress) {
        try {
            shipAddress = JSON.parse(shippingAddress);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    //for delivery address......
    const deliveryAddress = Orders?.Delivery_Tracking_Payload_Json;
    let delAddress = null;
    if (deliveryAddress) {
        try {
            delAddress = JSON.parse(deliveryAddress);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const handleClickOpen = (orderId) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setInputValue("")
    };

    const handleConfirm = async (order_Id) => {
        setLoading(true);
        const formData = {
            "Cashback_Amount": inputValue,
        };
        const result = await dispatch(OrderUserCashback(order_Id, formData));
        setLoading(false);
        if (result.data.code === "Success") {
            navigate('/app/cmpltd-order-history')
            // handleClose();
        } else {
            toast(result.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
        setOpen(false);
    };

    const [openPartner, setOpenPartner] = useState(false);

    const handleClickPartner = (orderId) => {
        setOpenPartner(true);
    };

    const handleClosePartner = () => {
        setOpenPartner(false);
    };

    const handleConfirmSettle = async (order_Id) => {
        setLoading(true);
        const result = await dispatch(SettleToPartner(order_Id));
        setLoading(false);
        if (result.data.code === "Success") {
            navigate('/app/cmpltd-order-history')
            // handleClose();
        } else {
            toast(result.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
        setOpenPartner(false);
    };


    const isBeforeReturnDate = (Created_Date, Return_Days) => {
        const date = new Date(Created_Date?.Date);
        const compareDate = new Date(date.setDate(date.getDate() + Return_Days));
        const currentDate = new Date();
        // console.log(currentDate, compareDate, Created_Date, Return_Days)
        return ((currentDate >= compareDate));
    }

    const handleInputChange = (e) => {
        const value = e.target.value;
        // Check if the entered value is less than or equal to Orders.Cashback
        if (!isNaN(value) && Number(value) <= Orders?.Cashback) {
            setInputValue(value); // Update the input value if valid
        } else if (value === "") {
            setInputValue(""); // Allow clearing the input
        }
    };

    const preventInvalidInput = (e) => {
        // Prevent entering negative signs and invalid characters
        if (e.key === '-' || e.key === 'e' || e.key === '+') {
            e.preventDefault();
        }
    };

    const orderDates = Orders?.Order_Status_Dates_Json
    const OrderStepper = ({ orderDates }) => {
        return (
            <Stepper orientation="vertical" style={{ padding: '20px' }}>
                {orderDates && Object.entries(orderDates).map(([key, { Date, BY, ID }]) => (
                    <Step key={key} active>
                        <StepLabel>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '8px' }}>
                                {/* <CalendarTodayIcon color="primary" /> */}
                                <strong>Status: {key}</strong>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <CalendarTodayIcon fontSize="small" />
                                <span>Date: {Date}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <PersonIcon fontSize="small" />
                                <span>By: {BY}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <BadgeIcon fontSize="small" />
                                <span>ID: {ID}</span>
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        );
    };

    return (
        <>
            <Card>
                <CardHeader title="Order Details" />
                <Divider />
                <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                    {/* Order Information */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Order Information
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                                <Typography variant="body1" style={{ marginRight: '1rem' }}>Order ID: {Orders?.Order_Id}</Typography>
                                {Orders?.Exchange_Reference_Retail_Order_Id && (
                                    <Typography variant="body1" style={{ fontWeight: 'bold', color: 'orange' }}>EXCHANGED ORDER</Typography>
                                )}
                            </div>
                            <Typography variant="body2">
                                Order Status: <span style={{ fontWeight: 'bold' }}>{Orders?.Order_Status}</span>
                            </Typography>
                            <Typography variant="body2">User Email ID: {Orders?.Email_Id}</Typography>
                            <Typography variant="body2">Order Partner Detail ID: {Orders?.Partner_Detail_Id}</Typography>
                            <Typography variant="body2">Order Partner Name: {Orders?.Name}</Typography>
                            {/* <Typography variant="body2">Created Date: {Orders?.Created_Date}</Typography>
                            <Typography variant="body2">Modified Date: {Orders?.Modified_Date}</Typography> */}
                            {Orders?.Partner_Product_Id &&
                                <Typography variant="body2">Partner Product Id: {Orders?.Partner_Product_Id}</Typography>}

                            {Orders?.Gift_History_Id &&
                                <Typography variant="body2">Gift History Id: {Orders?.Gift_History_Id}</Typography>}

                            {Orders?.Gift_Wrap_Message &&
                                <Typography variant="body2">Gift Wrap Msg: {Orders?.Gift_Wrap_Message}</Typography>}

                            <OrderStepper orderDates={orderDates} />
                        </Paper>
                    </Grid>

                    {/* Billing Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Billing Details
                            </Typography>
                            <Typography variant="body2">Address ID: {billAddress?.USER_ADDRESS_ID}</Typography>
                            <Typography variant="body2">Email ID: {billAddress?.USER_EMAIL_ID}</Typography>
                            <Typography variant="body2">Address Type: {billAddress?.ADDRESS_TYPE}</Typography>
                            <Typography variant="body2">Landmark: {billAddress?.LAND_MARK}</Typography>
                            <Typography variant="body2">City: {billAddress?.CITY}</Typography>
                            <Typography variant="body2">State: {billAddress?.STATE}</Typography>
                            <Typography variant="body2">Country: {billAddress?.COUNTRY}</Typography>
                            <Typography variant="body2">ZIP: {billAddress?.ZIP}</Typography>
                        </Paper>
                    </Grid>

                    {/* Payment Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Payment Details
                            </Typography>
                            <Typography variant="body2">Total Order Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Total_Order_Value}</span> </Typography>
                            <Typography variant="body2">Total Tax Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Total_Tax}</span></Typography>
                            <Typography variant="body2">VAT Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Vat}</span></Typography>
                            <Typography variant="body2">Meepaisa Comission Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Meepaisa_Commission_Amount}</span> </Typography>
                            <Typography variant="body2">Payment Method: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Type}</span></Typography>
                            <Typography variant="body2">Payment Reference ID: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Transaction_Id}</span></Typography>
                            <Typography variant="body2">Sgst: <span style={{ fontWeight: 'bold' }}>{Orders?.Sgst}</span></Typography>
                            <Typography variant="body2">Wallet Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_Amount}</span></Typography>
                            <Typography variant="body2">Wallet History Id: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_History_Id}</span></Typography>
                            {Orders?.Exchange_Reference_Retail_Order_Id ?
                                <span style={{ fontWeight: 'bold', color: 'green' }}>Payment Status : SUCCESS</span>
                                :
                                <Typography variant="body2" style={{ color: Orders?.Payment_Transaction_Status === "SUCCESS" ? 'green' : 'red' }}>
                                    Payment Status: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Transaction_Status === "SUCCESS" ? "SUCCESS" : "FAIL"}</span>
                                </Typography>
                            }
                            <Typography variant="body2">Wallet Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_Amount}</span></Typography>
                            <Typography variant="body2">Partner Coupon Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Partner_Coupon_Amount}</span></Typography>

                            {Orders?.Partner_Settlement_Amount &&
                                <Typography variant="body2">Partner Settlement Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Partner_Settlement_Amount}</span></Typography>}
                            <Typography variant="body2">Gift Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Gift_Amount}</span></Typography>
                            {Orders?.Gift_Wrap_Amount &&
                                <Typography variant="body2">Gift Wrap Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Gift_Wrap_Amount}</span></Typography>}
                            <Typography variant="body2">Shipping Fee: <span style={{ fontWeight: 'bold' }}>{Orders?.Shipping_Amount}</span></Typography>

                            {Orders?.Cashback == null || 0 ?
                                <></> :
                                <div>Cashback Amount: {Orders.Cashback}</div>
                            }
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                                <div>
                                    User Cashback Status:
                                    <span style={{ color: Orders?.Cashback_Settlement_To_User === 1 ? '#27b6cc' : 'red', fontWeight: 'bold' }}>
                                        {Orders?.Cashback_Settlement_To_User === 1 ? "  SETTLED" : "  NOT SETTLED"}
                                    </span>
                                </div>

                                {/* {(Orders?.Cashback !== null && Orders?.Cashback_Settlement_To_User == null && Number(Orders?.Cashback_Settlement_To_User) === 0 && isBeforeReturnDate(Orders?.Order_Status_Dates_Json?.DELIVERED, Orders?.Return_Days)) ? ( */}

                                {(Orders?.Cashback !== null && Orders?.Cashback_Settlement_To_User === 0 && isBeforeReturnDate(Orders?.Order_Status_Dates_Json?.DELIVERED, Orders?.Return_Days)) ? (
                                    <Button
                                        style={{ backgroundColor: '#27B6CC', marginLeft: 'auto', height: '30px', width: '170px' }}
                                        variant="contained"
                                        onClick={() => handleClickOpen(orderId)}
                                    >
                                        <span style={{ color: 'white' }}>CASHBACK TO USER</span>
                                    </Button>
                                ) : null}
                            </div>

                            <div>Partner Settlement Amount: {Orders.Partner_Settlement_Amount}</div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                                <div>
                                    Partner Settlement Status:
                                    <span style={{ color: Orders?.Partner_Settlement_Status === 1 ? '#27b6cc' : 'red', fontWeight: 'bold' }}>
                                        {Orders?.Partner_Settlement_Status === 1 ? "  SETTLED" : "  NOT SETTLED"}
                                    </span>

                                    <Typography variant="body2">Partner Settlement ID: <span style={{ fontWeight: 'bold' }}>{Orders?.Partner_Settlement_Id}</span></Typography>

                                </div>

                                {(Orders?.Partner_Settlement_Amount !== null && Orders?.Partner_Settlement_Status == 0 && isBeforeReturnDate(Orders?.Order_Status_Dates_Json?.DELIVERED, Orders?.Return_Days)) ?
                                    (
                                        <Button
                                            style={{ backgroundColor: '#27B6CC', marginLeft: 'auto', height: '30px', width: '175px' }}
                                            variant="contained"
                                            onClick={() => handleClickPartner(order_Id)}
                                        >
                                            <span style={{ color: 'white' }}>SETTLE TO PARTNER</span>
                                        </Button>
                                    ) : null}
                            </div>
                            <Typography variant="body2">Transaction Id: <span style={{ fontWeight: 'bold' }}>{Orders?.Transaction_Id}</span></Typography>

                        </Paper>
                    </Grid>

                    {/* Shipping Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Shipping Details
                            </Typography>
                            <Typography variant="body2">Address ID: {shipAddress?.USER_ADDRESS_ID}</Typography>
                            <Typography variant="body2">Email ID: {shipAddress?.USER_EMAIL_ID}</Typography>
                            <Typography variant="body2">Address Type: {shipAddress?.ADDRESS_TYPE}</Typography>
                            <Typography variant="body2">Landmark: {shipAddress?.LAND_MARK}</Typography>
                            <Typography variant="body2">City: {shipAddress?.CITY}</Typography>
                            <Typography variant="body2">State: {shipAddress?.STATE}</Typography>
                            <Typography variant="body2">Country: {shipAddress?.COUNTRY}</Typography>
                            <Typography variant="body2">ZIP: {shipAddress?.ZIP}</Typography>
                        </Paper>
                    </Grid>

                    {delAddress &&
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} style={{ padding: '1rem' }}>
                                <Typography variant="h6" gutterBottom>
                                    Delivery Details
                                </Typography>
                                <Typography variant="body2">Order ID: {delAddress?.order_number}</Typography>
                                <Typography variant="body2">Amount: {delAddress?.order_items[0]?.price}</Typography>
                                <Typography variant="body2">Payment Type: {delAddress?.payment_type}</Typography>
                                <Typography variant="body2">User Name: {delAddress?.consignee?.name}</Typography>
                                <Typography variant="body2">Delivery Address: {delAddress?.consignee?.address}</Typography>
                                <Typography variant="body2">Pickup Address: {delAddress?.pickup?.address}</Typography>
                                <Typography variant="body2">User Mob No: {delAddress?.consignee?.phone}</Typography>
                            </Paper>
                        </Grid>
                    }

                    {/* Product Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Product Details
                            </Typography>
                            <Typography variant="body2">Product Name: <span style={{ fontWeight: 'bold' }}>{Orders.Product_Name}</span> </Typography>
                            <Typography variant="body2">Quantity: <span style={{ fontWeight: 'bold' }}>{Orders.Quantity}</span></Typography>
                            <Typography variant="body2">Price Per Quantity: <span style={{ fontWeight: 'bold' }}>{Orders.Quantity_Selling_Price}</span></Typography>
                            <Typography variant="body2">Selling Price: <span style={{ fontWeight: 'bold' }}>{Orders.Base_Selling_Price}</span></Typography>
                            <Typography variant="body2">MRP: <span style={{ fontWeight: 'bold' }}>{Orders.Mrp}</span></Typography>
                            <Typography variant="body2">Size: <span style={{ fontWeight: 'bold' }}>{Orders.Size} {Orders.Size_Measurement}</span></Typography>
                        </Paper>
                    </Grid>
                </Grid>
                <Divider />

                <Dialog open={open}>
                    <DialogTitle>Cashback for User
                        {/* <Tooltip title="Cashback to User can be provided once the return period for that product is completed.">
                        </Tooltip> */}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You sure to provide CASHBACK to USER for this Order Id: <strong>{orderId}</strong>?
                        </DialogContentText>

                        <TextField
                            autoFocus
                            label="Enter Cashback"
                            type="number"
                            style={{ width: '200px', alignSelf: 'center' }}
                            value={inputValue}
                            onChange={handleInputChange}
                            helperText={
                                <span style={{ color: 'red' }}>
                                    Max cashback: {Orders?.Cashback} /-
                                </span>
                            }
                            inputProps={{ min: 1 }} // Prevents entering values less than 1 using arrows
                            onKeyDown={(e) => preventInvalidInput(e)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={inputValue < 1 && loading} onClick={() => handleConfirm(order_Id)}
                            style={{ backgroundColor: '#27B6CC', margin: "1%" }} variant="contained">
                            {/* Confirm */}
                            {loading ? (
                                <CircularProgress size={24} style={{ color: 'white' }} />
                            ) : (
                                <span style={{ color: 'white' }}>Confirm</span>
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openPartner}>
                    <DialogTitle>Settle for partner
                        {/* <Tooltip title="Settlement to partner can be done once the return period for this @ ORDER is completed.">
                        </Tooltip> */}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You sure to settle the AMOUNT to PARTNER with Order Id: <strong>{order_Id}</strong>?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePartner} >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleConfirmSettle(order_Id)} variant="contained" disabled={loading}
                            style={{ backgroundColor: '#27B6CC', margin: "1%" }}>
                            {/* <span style={{ color: 'white' }}>CONFIRM</span> */}
                            {loading ? (
                                <CircularProgress size={24} style={{ color: 'white' }} />
                            ) : (
                                <span style={{ color: 'white' }}>Confirm</span>
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Card>

        </>
    )
}

export default EditCmpltdOrderStatusHistory;

