import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  CircularProgress
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { ConnectedFocusError } from 'focus-formik-error';
import { AddPartnerManagementAction} from 'src/store/actions/servicespartnermanagementAction';
import { paginationPartnermanagementServices, paginationsubcategoryServices } from 'src/store/actions/node-actions/nodepagination';


const StatusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'ACTIVE',
    label: 'ACTIVE'
  },
  {
    value: 'IN-ACTIVE',
    label: 'IN-ACTIVE'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  Partner_Details_Id: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddServicespartnermanagement = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState({ Ss_Id: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmitted = useSelector(state => state.subcategory.subcategoryCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const [subcategories, setSubcategorires] = useState([]);
  const [partnerservice, setpartnerservice] = useState([]);
  
  useEffect(() => {
    dispatch(paginationsubcategoryServices({}, {}, (Status, data) => {
      if (Status) {
        setSubcategorires(data)
      }
      else
        setSubcategorires([])
    }));
  }, []);

  useEffect(() => {
    dispatch(paginationPartnermanagementServices({}, {}, (Status, data) => {
      if (Status) {
        setpartnerservice(data)
      }
      else
        setpartnerservice([])
    }));
  }, []);

  return (
    <Formik
      initialValues={{
        Ss_Id: '',
        Created_By: loginEmail,
        Partner_Details_Id: '',
        Status: '',
      }}
      validationSchema={Yup.object().shape({
        Ss_Id: Yup.string().min(5, 'Sub Service Name must be at least 5 characters.').notOneOf([errorMessage.Ss_Id.key, null], errorMessage.Ss_Id.message).required('Sub Service Name is required.'),
        Status: Yup.string().required('Status is required.'),
        Partner_Details_Id: Yup.string().required('Partner Name is required.'),

      })}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (submitTest) {
          dispatch(AddPartnerManagementAction(values, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/* Partner_Details_Id */}
                    <Grid item md={12} xs={12}>
                    <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Partner Name"
                        name="Partner_Details_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Partner_Details_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {partnerservice?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map((option, index) => (
                          <option key={index} value={option.Partner_Details_Id}>
                            {option.Name}
                      
                          </option>
                        ))}
                      </TextField>
                    
                      {touched.Partner_Details_Id && errors.Partner_Details_Id && (
                        <span style={{ color: 'red' }}>{errors.Partner_Details_Id}</span>
                      )}
                    </Grid>
                    {/* Service Name */}
                    <Grid item md={12} xs={12}>
                    <TextField
                        error={Boolean(touched.Ss_Id && errors.Ss_Id)}
                        fullWidth
                        helperText={touched.Ss_Id && errors.Ss_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="sub Service Name"
                        name="Ss_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Ss_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {subcategories?.sort((a, b) => a.Ss_Name?.localeCompare(b.Ss_Name))?.map((option, index) => (
                          <option key={index} value={option.Ss_Id}>
                            {option.Ss_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                   
                    
                    
                    {/* Status */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {StatusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
          
              </Grid>
            </CardContent>
            <Box display="flex" p={2}>
              {isSubmitted ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={isSubmitted}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              )}
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddServicespartnermanagement.propTypes = {
  className: PropTypes.string
};

export default AddServicespartnermanagement;
