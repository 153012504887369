import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  InputLabel,
  Card,
  CardContent,
  Grid,
  TextField,
  Avatar,
  FormControl,
  Select,
  makeStyles,
  MenuItem,
  Button,
  CircularProgress
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { ConnectedFocusError } from 'focus-formik-error';
import { VarientuploadImagepath } from 'src/store/actions/servicesvarientAction';
import { AddServiceRequestAction } from 'src/store/actions/servicepartnerRequestAction';
import {
  paginationsubcategoryServices,
  paginationrequestServices,
  paginationServicescategory
} from 'src/store/actions/node-actions/nodepagination';
import { getAllServiceCategories } from 'src/store/actions/servicepartnerRequestAction';

const ServiceOption = [
  {
      value: '',
      label: '-- Please Select --'
  },
  {
    value: 'Services',
    label: 'Services'
},
{
    value: 'Sub Services',
    label: 'Sub Services'
},
{
  value: 'Variants',
  label: 'Variants'
}
];

const StatusOption = [
  {
      value: '',
      label: '-- Please Select --'
  },
  {
    value: 'success',
    label: 'success'
},
{
    value: 'pending',
    label: 'pending'
},
{
  value: 'hold',
  label: 'hold'
},
{
  value: 'rejected',
  label: 'rejected'
}
];


const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  Description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddServiceRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAllServiceCategories());
  }, []);

  let { state } = location;
  const [type, setType] = useState('');
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false); 
  const [errorMessage, setErrorMessage] = useState({ Request_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  // const serviceData = location.state?.serviceData || {};
  const [Requests, setRequest] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const categories = useSelector(state => state.subcategory.Categories.categories);
  // const [services, setServices] = useState([]);
  const isSubmitted = useSelector(
    state => state.category.categoryUpdate.isLoading
  );
  const createdBy = useSelector(state => state.auth.user.Email_Id);

  // Populate the initialValues with serviceData
  const initialValues = {
    Image: '',
    Request_Type:  '',
    Request_Name:  '',
    Description: '',
    Status_Feedback:  '',
    Created_By: '',
    Service_Id: '',
    Ss_Id: '',
    Status: '',
  };

  // const handleChange = (event, setFieldValue) => {
  //   setFieldValue("type", event.target.value);
  // };

  

  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = (e) => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    // Simulate async image upload
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      VarientuploadImagepath(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = () => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  // useEffect(() => {
  //   // Fetch services on component mount
  //   dispatch(
  //     paginationServicescategory({}, {}, (status, data) => {
  //       if (status) {
  //         setServices(data);
  //       }
  //     })
  //   );
  // }, [dispatch]);
  
  useEffect(() => {
    // Fetch sub-services when Service_Id is available
    
      dispatch(
        paginationsubcategoryServices({ }, {}, (status, data) => {
          if (status) {
            setRequest(data);
          }
        })
      );

  }, [ dispatch]);


  // Yup validation schema
  const validationSchema = Yup.object().shape({
    // Request_Type: Yup.string().required('Request type is required.'),
    Request_Name: Yup.string()
      .min(3, 'Request name must be at least 3 characters.')
      .required('Request name is required.'),
    Description: Yup.string()
      .min(20, 'Description must be at least 20 characters.')
      .required('Description is required.'),
    Status : Yup.string().required('Status is required.'),  
    Status_Feedback: Yup.string().required('Status is required.'),
    Service_Id: Yup.string().when('Request_Type', {
      is: 'Sub Services' || 'Variants',
      then: Yup.string().required('Service name is required.'),
    }),
    Ss_Id: Yup.string().when('Request_Type', {
      is: 'Variants',
      then: Yup.string().required('Sub service name is required.'),
    })
  })
  const onSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(false);
    let submitTest = true;
    const errors = {};
    values.Image = imgUrl;
    
    if (!values.Image) {
      errors.Image = "Image is required.";
      submitTest = false;
    } 
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      formValues.Created_By = createdBy;
      dispatch(AddServiceRequestAction(formValues, navigate, setErrorMessage, setErrors));
    }
  }

  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    // onSubmit={(values) => {
    //   const payload = {
    //     Request_Type: values.Request_Type,
    //     Request_Name: values.Request_Name,
    //     Description: values.Request_Type,
    //     Image: imgUrl, 
    //     Status_Feedback: values.Status_Feedback
    //   };
    //   dispatch(EditPartnerServiceRequestAction(payload));
    // }}
    >
      {({ handleSubmit,handleBlur, handleChange, setFieldValue, errors, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box>
                <Grid container spacing={4}>
                  {/* Form Fields - Left Side */}
                  <Grid item xs={12} md={8}>
                    <Box>
                      <Box
                        sx={{
                          minWidth: 120,
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: 4
                        }}
                      >
                        {/* Type Select */}
                        <Box sx={{ minWidth: '50%' }}>
                          <FormControl fullWidth variant="outlined">
                            {/* <InputLabel id=" type">Type</InputLabel> */}
                            {/* <Select
                              labelId="type"
                              id="type-select"
                              value={type}
                              label="Type"
                              onChange={(e) => setType(e.target.value)}
                              name="type"
                            >
                              <MenuItem value="Services">Services</MenuItem>
                              <MenuItem value="Sub Services">Sub Services</MenuItem>
                              <MenuItem value="Variants">Variants</MenuItem>
                            </Select> */}
                            <TextField
                          error={Boolean(touched.Request_Type && errors.Request_Type)}
                          fullWidth
                          helperText={touched.Request_Type && errors.Request_Type}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setType(e.target.value); 
                            handleChange(e); 
                          }}
                          label="Request Type"
                          name="Request_Type"
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ native: true }}
                          value={values.Request_Type}
                          variant="outlined"
                        >
                         {ServiceOption.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                          </FormControl>
                        </Box>

                        {/* Conditionally Rendered Fields */}
                        {type === 'Services' && (
                          <Box sx={{ flex: 1 }}>
                            <TextField
                              error={Boolean(touched.Request_Name && errors.Request_Name)}
                              fullWidth
                              helperText={touched.Request_Name && errors.Request_Name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label="Service Name"
                              name="Request_Name"
                              value={values.Request_Name}
                              variant="outlined"
                            />
                          </Box>
                        )}
                        {type === 'Sub Services' && (
                          <Box sx={{ flex: 1 }}>
                            <TextField
                              error={Boolean(touched.Request_Name && errors.Request_Name)}
                              fullWidth
                              helperText={touched.Request_Name && errors.Request_Name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label="Sub Service Name"
                              name="Request_Name"
                              value={values.Request_Name}
                              variant="outlined"
                            />
                          </Box>
                        )}
                        {type === 'Variants' && (
                          <Box sx={{ flex: 1 }}>
                            <TextField
                              error={Boolean(touched.Request_Name && errors.Request_Name)}
                              fullWidth
                              helperText={touched.Request_Name && errors.Request_Name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label="Variant Name"
                              name="Request_Name"
                              value={values.Request_Name}
                              variant="outlined"
                            />
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          minWidth: 120,
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: 4,
                          marginTop: 12,
                          marginBottom: "12px"
                        }}>

                        {(type === 'Sub Services' || type === 'Variants') && (
                          <Box sx={{ flex: 1, minWidth: '50%' }}>
                            <FormControl fullWidth variant="outlined">
                            <TextField
                        error={Boolean(touched.Service_Id && errors.Service_Id)}
                        fullWidth
                        helperText={touched.Service_Id && errors.Service_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Service Name"
                        name="Service_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Service_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {categories?.sort((a, b) => a.Service_Name?.localeCompare(b.Service_Name))?.map(option => (
                          <option key={option.Service_Id} value={option.Service_Id}>
                            {option.Service_Name}
                          </option>
                        ))}
                      </TextField>

                            </FormControl>
                          </Box>
                        )}

                        {type === 'Variants' && (
                          <Box sx={{ flex: 1, minWidth: '50%' }}>
                            <FormControl fullWidth variant="outlined">
                            <TextField
                        error={Boolean(touched.Ss_Id && errors.Ss_Id)}
                        fullWidth
                        helperText={touched.Ss_Id && errors.Ss_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Sub Service Name"
                        name="Ss_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Ss_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {Requests?.sort((a, b) => a.Ss_Name?.localeCompare(b.Ss_Name))?.map(option => (
                          <option key={option.Ss_Id} value={option.Ss_Id}>
                            {option.Ss_Name}
                          </option>
                        ))}
                      </TextField>
                            </FormControl>
                          </Box>
                        )}

                      </Box>

                      {/* Description Field */}
                      <Grid item md={12} xs={12}>
                        <Box>
                        <TextareaAutosize
                            className={classes.Description}
                            aria-label="description"
                            placeholder="Description"
                            minRows={10}
                            style={{ width: '100%' }}
                          />
                        </Box>
                        {/* {errors.Description && touched.Description && (
                          <Box style={{ color: 'red' }}>{errors.Description}</Box>
                        )} */}
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Box >
                        <FormControl fullWidth variant="outlined" style={{ marginTop: '10px' }}>
                          <TextareaAutosize
                            className={classes.Status_Feedback}
                            aria-label="Status Feedback"
                            placeholder="Status Feedback"
                            minRows={10}
                            style={{ width: '100%' , padding: '10px'}}
                          />
                          {/* {errors.feedback && touched.feedback && (
                            <div style={{ color: 'red' }}>{errors.feedback}</div>
                          )} */}
                          </FormControl>
                        </Box>
                      </Grid>

                      {/* Status Field */}
                      <Grid item md={12} xs={12}>
                        <FormControl fullWidth variant="outlined" style={{ marginTop: '10px' }}>
                        <TextField
                          error={Boolean(touched.Status && errors.Status)}
                          fullWidth
                          helperText={touched.Status && errors.Status}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Status"
                          name="Status"
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ native: true }}
                          value={values.Status}
                          variant="outlined"
                        >
                         {StatusOption.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                        </FormControl>
                      </Grid>
                    </Box>
                  </Grid>

                  {/* Form Fields - Right Side */}
                  <Grid item md={4} xs={12}>
                    <Card>
                      &nbsp;Web Image
                      <CardContent>
                        <Box display="flex" justifyContent="center">
                          {image.preview ? (
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src={image.preview}
                              variant="square"
                            />
                          ) : (
                            <>
                              <Avatar
                                className={classes.images}
                                alt="Brand Upload"
                                src="/static/images/image-placeholder.png"
                                variant="square"
                              />
                            </>
                          )}
                        </Box>

                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          {image.preview ? ( <Button
                            style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                            onClick={handleRemoveImage}
                          >
                            Remove Picture
                          </Button> ): (<input
                            style={{ display: 'block', width: '80%' }}
                            id="upload-photo"
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={handleImage}
                          />)}
                          <br />
                        </Box>
                      </CardContent>
                    </Card>
                    <span style={{ color: "red" }}>
                      {!imgUrl && touched.Image && errors.Image}
                    </span>




                  </Grid>
                </Grid>
                  <Box display="flex" pl={2} >
                    <span style={{ color: "red" }}>{errorMessage.global.message}</span>
                  </Box>

                {/* Submit Button */}
                <Box display="flex" marginTop={4}>
                {isSubmitted ? (
                <CircularProgress />
              ) : (

                  <Button 
                   disabled={isSubmitted || uploadPic}
                  type="submit" 
                  variant="contained" 
                  style={{ backgroundColor: '#27B6CC' }}>
                    <span style={{ color: 'white' }}>Submit</span>

                  </Button>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};


AddServiceRequest.propTypes = {
  className: PropTypes.string
};

export default AddServiceRequest;
