import { Card, CardContent, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { invoiceget } from 'src/store/actions/ordersAction';



const OrderInvoiceView = ({ className, state, data, ...rest }) => {
    const Print = () => {
        // Create a new window
        let printWindow = window.open('', '_blank');
        // Write the print contents and styles to the new window
        printWindow.document.write('<html><head><title>Print</title>');
        printWindow.document.write(`<style> .page-break { page-break-after: always; } 
        table {
            width: 100%;
            border-collapse: collapse;
            margin: 2px;
        }
        th, td {
            border: 1px solid #000;
            padding: 2px;
        }
        th {
            background-color: #f2f2f2;
        }
        .container {
            display: flex;
            justify-content: space-between;
            margin: 2px;
        }
        .left,
        .right {
            flex: 1;
            text-align: left; /* Adjust alignment as needed */
        }
        .right {
            text-align: right; /* Adjust alignment as needed */
            margin-top: -18px;
        }
        div p {
            margin: 0.5; /* Remove default margin */
            font-weight: bold;
        }
        div p span:first-child {
            font-weight: bold; /* Make the first span (inside p) bold */
        }
        div p .normal-text {
            font-weight: normal; /* Make the second span (inside p) normal */
        }
        div p span {
            display: inline-block; /* Display spans inline */
        }
        </style>`);
        printWindow.document.write('</head><body onclick="window.print()" >');
        printWindow.document.write(
            `<div>
             <span style="text-align:center">
                <div><p><span><span>Tax Invoice</span></p></div>
             </span>
             <div class="container">
             <span class="left">
                <img height="40px" src= "/static/meepaisalogo_bw.jpg" alt="Logo"></img>
                <div><p><span>Sold By(Seller Partner Address):</span></p></div>
                <div>meepaisa</div>
                <div>Plot No.5B, Ground Floor, Regent Gateway</div>
                <div>ITPL Main Rd, beside Advaith Hyundai Service Center</div>
                <div>Whitefield, Bengaluru, Karnataka</div>
                <div>India</div>
                <div>560037</div>
                <div><p><span>Order Details:</span></p></div>
                <p><span>Order Number:</span>`+ state?._id + `</p>
                <p><span>Order Date:</span>`+ state?.Ordered_Date + `</p>
                <p><span>Invoice Number:</span>`+ state?.Invoice_Id + `</p>
                <p><span>Invoice Date:</span>`+ state?.Ordered_Date + `</p>
                <p><span>PAN Number:</span> AAECE4620R</p>
                <p><span>GSTIN:</span> 29AAECE4620R1Z5</p>
             </span>
             <span class="right">
                <img height="80" src="/static/ELEV8 Wordmark BK.png" alt="Logo" style="margin-bottom: -24px"></img>
                <div><p><span>Billing Address:</span></p></div>
                <div>`+ state?.billingAddress?.Company_Name + `</div>
                <div>`+ state?.billingAddress?.Mobile_Number + `</div>
                <div>`+ state?.billingAddress?.Address + `</div>
                <div>`+ state?.billingAddress?.City + `</div>
                <div>`+ state?.billingAddress?.State + `</div>
                <div>`+ state?.billingAddress?.Country + `</div>
                <div>`+ state?.billingAddress?.Zip_Code + `</div>
                <div><p><span>Shipping Address:</span></p></div>
                <div>`+ state?.shippingAddress?.Full_Name + `</div>
                <div>`+ state?.shippingAddress?.Mobile_Number + `</div>
                <div>`+ state?.shippingAddress?.Address + `</div>
                <div>`+ state?.shippingAddress?.City + `</div>
                <div>`+ state?.shippingAddress?.State + `</div>
                <div>`+ state?.shippingAddress?.Country + `</div>
                <div>`+ state?.shippingAddress?.Zip_Code + `</div>
             </span>
             </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th >Product Name</th>
                    <th >Quantity</th>
                    <th >Discount</th>
                    <th >GST</th>
                    <th >Price</th>
                </tr>
            </thead>
            <tbody>`);

        let data = state?.Ordered_Products?.map((prod, index) => {
            printWindow.document.write(`<tr >
                <th>`+ prod.Product_Name + `</th>
                <th>`+ prod.Quantity + `</th>
                <th>`+ prod.Discount_Price_On_Product + `</th>
                <th>
                    CGST: `+ prod.Cgst + `<br />
                    SGST: `+ prod.Sgst + `
                </th>
                <th>`+ prod.Finalized_Discounted_Price + `</th>
            </tr>`);
        })

        printWindow.document.write(`
        </tbody>
        </table>
        <div style="margin-top: 2px;, margin-bottom: 2px">
        <div><p><span> Total: `+ state?.Finalized_MRP + `</span></p></div>
        <div><p><span> Amount in Words: `+ state?.Total_Amount_In_Words + `</span></p></div>
        </div>
        <div class="container" style="margin-top: 2px;, margin-bottom: 2px">
        <span class="left">
        <img height="40" src="/static/invoice_signature.png"></img>
        <div><p><span> Authorized Signatory</span></p></div>
        </span>
        <span class="right">
        <img height="120" src="/static/terms_cond_qr.png"></img>
        </span>
        </div>
        <div style="margin-top: 2px;, margin-bottom: 2px"><p><span>Note: If Undelivered return to Seller Partner Address.</span></p></div>
        </div>`);
        // printWindow.document.write(printContents);
        printWindow.document.write('</body></html>');

        // Print the contents
        // printWindow.print();
        // Close the window
        // printWindow.close();

    }


    return (
        <Card>
            <CardContent>

                <Box display="flex" p={2}>
                    <Button
                        style={{ backgroundColor: '#27B6CC' }}
                        variant="contained"
                        onClick={() => { Print() }}
                    >
                        <span style={{ color: 'white' }}>PRINT</span>
                    </Button>
                </Box>

            </CardContent>
        </Card >
    );
};


export default OrderInvoiceView;