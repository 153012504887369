import React, { useState, useEffect } from 'react';
import {
    Box, Button, Card, CardContent, Grid, TextField, makeStyles, CardHeader, Divider, ListItemAvatar,
    ListItemText, Dialog, DialogTitle, IconButton, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getsingleOrderAction, getsingleOrderActionNew, OrderRefundAmount } from 'src/store/actions/ordersAction';
import { toast } from 'react-toastify'
import isEmpty from 'src/store/validations/is-empty';
import { Typography, Paper, CircularProgress, Step, StepLabel, Stepper } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';

const EditCnclldOrderStatusHistory = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { orderId } = location.state || {};
    const Orders = useSelector(state => state?.orders?.singleorder?.all);
    const order_Id = Orders?.Order_Id || null;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    //dispatch actions
    useEffect(() => {
        if (!isEmpty(orderId)) {
            const formData = {
                "Records_Filter": "FILTER",
                "Order_Id": orderId
            };
            dispatch(getsingleOrderActionNew(formData));
        }
    }, []);

    //for billing address......
    const address = Orders?.User_Billing_Address_Full;
    let billAddress = null;
    if (address) {
        try {
            billAddress = JSON.parse(address);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    //for shipping address......
    const shippingAddress = Orders?.User_Shipping_Address_Full;
    let shipAddress = null;
    if (shippingAddress) {
        try {
            shipAddress = JSON.parse(shippingAddress);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async (order_Id) => {
        setLoading(true);
        const result = await dispatch(OrderRefundAmount(order_Id));
        setLoading(false);
        if (result.data.code == "Success") {
            navigate('/app/cancelled-order-history')
            // handleClose();
        } else {
            toast(result.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
        setOpen(false);
    };

    const orderDates = Orders?.Order_Status_Dates_Json
    const OrderStepper = ({ orderDates }) => {
        return (
            <Stepper orientation="vertical" style={{ padding: '20px' }}>
                {orderDates && Object.entries(orderDates).map(([key, { Date, BY, ID }]) => (
                    <Step key={key} active>
                        <StepLabel>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '8px' }}>
                                {/* <CalendarTodayIcon color="primary" /> */}
                                <strong>Status: {key}</strong>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <CalendarTodayIcon fontSize="small" />
                                <span>Date: {Date}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <PersonIcon fontSize="small" />
                                <span>By: {BY}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <BadgeIcon fontSize="small" />
                                <span>ID: {ID}</span>
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        );
    };

    return (
        <>
            <Card>
                <CardHeader title="Order Details" />
                <Box display="flex" style={{ marginTop: "-50px" }} justifyContent="flex-end" p={1}>
                    <div style={{ marginLeft: '1rem', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>

                        {Orders?.Refund_Online_Amount == null && Orders?.Refund_Wallet_Amount == null ?
                            (
                                <Button
                                    style={{ backgroundColor: '#27B6CC', marginLeft: 'auto', height: '30px', width: '175px' }}
                                    variant="contained"
                                    onClick={handleClickOpen}
                                >
                                    <span style={{ color: 'white' }}>REFUND AMOUNT</span>
                                </Button>
                            ) : null}
                    </div>
                </Box>

                <Divider style={{ marginTop: '15px' }} />

                <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                    {/* Order Information */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Order Information
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                                <Typography variant="body1" style={{ marginRight: '1rem' }}>Order ID: {Orders?.Order_Id}</Typography>
                                {Orders?.Exchange_Reference_Retail_Order_Id && (
                                    <Typography variant="body1" style={{ fontWeight: 'bold', color: 'orange' }}>EXCHANGED ORDER</Typography>
                                )}
                            </div>
                            <Typography variant="body2">
                                Order Status: <span style={{ fontWeight: 'bold' }}>{Orders?.Order_Status}</span>
                            </Typography>
                            <Typography variant="body2">User Email ID: {Orders?.Email_Id}</Typography>
                            <Typography variant="body2">Partner Detail ID: {Orders?.Partner_Detail_Id}</Typography>
                            <Typography variant="body2">Partner Name: {Orders?.Name}</Typography>
                            {/* <Typography variant="body2">Created Date: {Orders?.Created_Date}</Typography>
                            <Typography variant="body2">Modified Date: {Orders?.Modified_Date}</Typography> */}
                            {Orders?.Decline_Reason &&
                                <Typography variant="body2">
                                    Declined Reason:
                                    <Typography component="span" variant="body1" style={{ fontWeight: 'bold', color: 'red', marginLeft: '7px' }}>
                                        {Orders?.Decline_Reason}
                                    </Typography>
                                </Typography>
                            }
                            {Orders?.Cancel_Return_Exchange_Reason_For &&
                                <Typography variant="body2">
                                    Cancel Reason For:
                                    <Typography component="span" variant="body1" style={{ fontWeight: 'bold', color: 'red', marginLeft: '7px' }}>
                                        {Orders?.Cancel_Return_Exchange_Reason_For}
                                    </Typography>
                                </Typography>
                            }

                            {Orders?.Cancel_Return_Exchange_Reason_Info &&
                                <Typography variant="body2">
                                    Cancel Reason Info:
                                    <Typography component="span" variant="body1" style={{ fontWeight: 'bold', color: 'red', marginLeft: '7px' }}>
                                        {Orders?.Cancel_Return_Exchange_Reason_Info}
                                    </Typography>
                                </Typography>
                            }

                            {Orders?.Partner_Product_Id &&
                                <Typography variant="body2">Partner Product Id: {Orders?.Partner_Product_Id}</Typography>}

                            {Orders?.Gift_History_Id &&
                                <Typography variant="body2">Gift History Id: {Orders?.Gift_History_Id}</Typography>}

                            {Orders?.Gift_Wrap_Message &&
                                <Typography variant="body2">Gift Wrap Msg: {Orders?.Gift_Wrap_Message}</Typography>}
                            <Typography variant="body2">Transaction Id: <span style={{ fontWeight: 'bold' }}>{Orders?.Transaction_Id}</span></Typography>

                            <OrderStepper orderDates={orderDates} />

                        </Paper>
                    </Grid>

                    {/* Billing Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Billing Details
                            </Typography>
                            <Typography variant="body2">Address ID: {billAddress?.USER_ADDRESS_ID}</Typography>
                            <Typography variant="body2">Email ID: {billAddress?.USER_EMAIL_ID}</Typography>
                            <Typography variant="body2">Address Type: {billAddress?.ADDRESS_TYPE}</Typography>
                            <Typography variant="body2">Landmark: {billAddress?.LAND_MARK}</Typography>
                            <Typography variant="body2">City: {billAddress?.CITY}</Typography>
                            <Typography variant="body2">State: {billAddress?.STATE}</Typography>
                            <Typography variant="body2">Country: {billAddress?.COUNTRY}</Typography>
                            <Typography variant="body2">ZIP: {billAddress?.ZIP}</Typography>
                        </Paper>
                    </Grid>

                    {/* Payment Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Payment Details
                            </Typography>
                            <Typography variant="body2">Total Order Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Total_Order_Value}</span></Typography>
                            <Typography variant="body2">Total Tax Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Total_Tax}</span></Typography>
                            <Typography variant="body2">VAT Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Vat}</span></Typography>
                            <Typography variant="body2">Payment Method: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Type}</span></Typography>
                            <Typography variant="body2">Payment Reference ID: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Transaction_Id}</span></Typography>
                            <Typography variant="body2" style={{ color: Orders?.Payment_Transaction_Status === "SUCCESS" ? 'green' : 'red' }}>
                                Payment Status: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Transaction_Status === "SUCCESS" ? "SUCCESS" : "FAIL"}</span>
                            </Typography>
                            <Typography variant="body2">Wallet Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_Amount}</span></Typography>
                            <Typography variant="body2">Shipping Fee: <span style={{ fontWeight: 'bold' }}>{Orders?.Shipping_Amount}</span></Typography>
                            {
                                Orders?.Refund_Online_Payment_Transaction_Id &&
                                <Typography variant="body2">Refunded Transaction ID: <span style={{ fontWeight: 'bold' }}>{Orders?.Refund_Online_Payment_Transaction_Id}</span></Typography>
                            }

                            {
                                Orders?.Refund_Wallet_History_Id &&
                                <Typography variant="body2">Refunded Wallet History ID: <span style={{ fontWeight: 'bold' }}>{Orders?.Refund_Wallet_History_Id}</span></Typography>
                            }

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                {(Orders.Refund_Online_Amount == null) || (Orders.Refund_Online_Amount == 0) ?
                                    <></>
                                    :
                                    <div>Refunded Online Amount: <span style={{ fontWeight: 'bold', color: '#27b6cc' }}>{Orders.Refund_Online_Amount}</span> </div>
                                }
                                {(Orders.Refund_Wallet_Amount == null) || (Orders.Refund_Wallet_Amount == 0) ?
                                    <></>
                                    :
                                    <div>Refunded Wallet Amount: <span style={{ fontWeight: 'bold', color: '#27b6cc' }}>{Orders.Refund_Wallet_Amount}</span></div>
                                }
                            </div>
                            <Typography variant="body2">Sgst: <span style={{ fontWeight: 'bold' }}>{Orders?.Sgst}</span></Typography>
                            <Typography variant="body2">Wallet Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_Amount}</span></Typography>
                            <Typography variant="body2">Wallet History Id: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_History_Id}</span></Typography>
                            <Typography variant="body2">Partner Coupon Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Partner_Coupon_Amount}</span></Typography>

                            {Orders?.Partner_Settlement_Amount &&
                                <Typography variant="body2">Partner Settlement Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Partner_Settlement_Amount}</span></Typography>}
                            <Typography variant="body2">Gift Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Gift_Amount}</span></Typography>
                            {Orders?.Gift_Wrap_Amount &&
                                <Typography variant="body2">Gift Wrap Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Gift_Wrap_Amount}</span></Typography>}
                        </Paper>
                    </Grid>

                    {/* Shipping Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Shipping Details
                            </Typography>
                            <Typography variant="body2">Address ID: {shipAddress?.USER_ADDRESS_ID}</Typography>
                            <Typography variant="body2">Email ID: {shipAddress?.USER_EMAIL_ID}</Typography>
                            <Typography variant="body2">Address Type: {shipAddress?.ADDRESS_TYPE}</Typography>
                            <Typography variant="body2">Landmark: {shipAddress?.LAND_MARK}</Typography>
                            <Typography variant="body2">City: {shipAddress?.CITY}</Typography>
                            <Typography variant="body2">State: {shipAddress?.STATE}</Typography>
                            <Typography variant="body2">Country: {shipAddress?.COUNTRY}</Typography>
                            <Typography variant="body2">ZIP: {shipAddress?.ZIP}</Typography>
                        </Paper>
                    </Grid>

                    {/* Product Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Product Details
                            </Typography>
                            <Typography variant="body2">Name: <span style={{ fontWeight: 'bold' }}>{Orders.Product_Name}</span> </Typography>
                            <Typography variant="body2">Quantity: <span style={{ fontWeight: 'bold' }}>{Orders.Quantity}</span></Typography>
                            <Typography variant="body2">Price Per Quantity: <span style={{ fontWeight: 'bold' }}>{Orders.Quantity_Selling_Price}</span></Typography>
                            <Typography variant="body2">Selling Price: <span style={{ fontWeight: 'bold' }}>{Orders.Base_Selling_Price}</span></Typography>
                            <Typography variant="body2">MRP: <span style={{ fontWeight: 'bold' }}>{Orders.Mrp}</span></Typography>
                            <Typography variant="body2">Size: <span style={{ fontWeight: 'bold' }}>{Orders.Size} {Orders.Size_Measurement}</span></Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <Divider />
                <Dialog open={open}>
                    <DialogTitle>Order Refund</DialogTitle>
                    <DialogContent>
                        <div>Sure you want to refund the amount for the order: {order_Id}?</div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleConfirm(order_Id)} disabled={loading}
                            style={{ backgroundColor: '#27B6CC', margin: "1%" }} variant="contained">
                            {/* Confirm */}
                            {loading ? (
                                <CircularProgress size={24} style={{ color: 'white' }} />
                            ) : (
                                <span style={{ color: 'white' }}>Confirm</span>
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Card>

        </>
    )
}

export default EditCnclldOrderStatusHistory;

