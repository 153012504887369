import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
  Dialog, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import Pagination from 'src/components/pagination/pagination';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Print } from '@material-ui/icons';
import { IconButton } from '@mui/material';
import { POS_Invoice_Get } from 'src/store/actions/posActions';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Created_Date', numeric: false, disablePadding: true, label: 'Order ID' },
  { id: 'Invoice_No', numeric: false, disablePadding: false, label: 'Invoice No' },
  { id: 'Created_Date', numeric: false, disablePadding: false, label: 'Created Date' },
  { id: 'Full_Name', numeric: false, disablePadding: false, label: 'Cust Name' },
  { id: 'Mobile', numeric: false, disablePadding: false, label: 'Cust Mob No' },
  { id: 'Payment_Amount', numeric: false, disablePadding: false, label: 'Total Amount' },
  { id: 'Total_Order_Value', numeric: false, disablePadding: false, label: 'Order Amount' },
  { id: 'Order_Type', numeric: false, disablePadding: false, label: 'Order Type' },
  { id: 'Payment_Type', numeric: false, disablePadding: false, label: 'Payment Type' },
  // { id: 'Order_Type', numeric: false, disablePadding: false, label: 'Created By' },
];

const Results = ({
  className,
  allOrders,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allOrdersNoFilter,
  pagination,
  setPagination,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedOrdersIds, setSelectedOrdersIds] = useState([]);
  const [invoiceDetails, setInvoice] = useState([]);

  const [open, setOpen] = useState(false); // State to manage the visibility of the popup
  const [selectedOrderId, setSelectedOrderId] = useState(null); // State to store the selected Order ID

  const handleOpen = (orderId) => {
    setSelectedOrderId(orderId); // Set the selected Order ID
    setOpen(true); // Open the popup
  };

  const handleClose = () => {
    setOpen(false); // Close the popup
    setSelectedOrderId(null); // Reset the Order ID when closing
  };

  const OrderLineItemStatusHandler = (orderId) => {
    handleOpen(orderId); // Call handleOpen with the Order ID
  };

  const Invoice_Get = async (orderId) => {
    let formData = {
      Order_Id: orderId,
    };

    try {
      const response = await POS_Invoice_Get(formData);
      if (response?.status) {
        // setInvoice(response.data);
        setTimeout(() => { InvoicePrint(response.data) }, 2000)
      } else {
        // setInvoice([]);
      }
    } catch (error) {
      // setInvoice([]);
    }
  };


  const InvoicePrint = (invoiceDetails = {}) => {
    // Create a new window for printing
    let printWindow = window.open('', '_blank');
    // Write the print contents and styles to the new window
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write(`<style> .page-break { page-break-after: always; } 
    table {
        width: 100%;
        border-collapse: collapse;
        margin: 2px;
    }
    th, td {
        border: 1px solid #000;
        padding: 2px;
    }
    th {
        background-color: #f2f2f2;
    }
    .container {
        display: flex;
        justify-content: space-between;
        margin: 2px;
    }
    .left,
    .right {
        flex: 1;
        text-align: left; /* Adjust alignment as needed */
    }
    .right {
        text-align: right; /* Adjust alignment as needed */
        margin-top: -18px;
    }
    div p {
        margin: 0.5; /* Remove default margin */
        font-weight: bold;
    }
    div p span:first-child {
        font-weight: bold; /* Make the first span (inside p) bold */
    }
    div p .normal-text {
        font-weight: normal; /* Make the second span (inside p) normal */
    }
    div p span {
        display: inline-block; /* Display spans inline */
    }
    </style>`);
    printWindow.document.write('</head><body onclick="window.print()" >');
    printWindow.document.write(
      `<div>
         <span style="text-align:center">
            <div><p><span><span>Tax Invoice</span></p></div>
         </span>
         <div class="container">
         <span class="left">
            <img height="40px" src= "/static/meepaisalogo_bw.jpg" alt="Logo"></img>
            <div><p><span>Sold By(Seller Partner Address):</span></p></div>
            <div>meepaisa</div>
            <div>Plot No.5B, Ground Floor, Regent Gateway</div>
            <div>ITPL Main Rd, beside Advaith Hyundai Service Center</div>
            <div>Whitefield, Bengaluru, Karnataka</div>
            <div>India</div>
            <div>560037</div>
            <div><p><span>Order Details:</span></p></div>
            <p><span>Order Number:</span>`+ invoiceDetails?.Order?.Order_Id + `</p>
            <p><span>Order Date:</span>`+ invoiceDetails?.Order?.Created_Date + `</p>
            <p><span>Invoice Number:</span>`+ invoiceDetails?.Order?.Invoice_No + `</p>
            <p><span>Invoice Date:</span>`+ invoiceDetails?.Order?.Created_Date + `</p>
            <p><span>PAN Number:</span> AAECE4620R</p>
            <p><span>GSTIN:</span> 29AAECE4620R1Z5</p>
         </span>
       
         </div>
    </div>
    <table>
        <thead>
            <tr>
                <th >Product Name</th>
                <th >Quantity</th>
                <th >Discount</th>
                <th >GST</th>
                <th >Price</th>
            </tr>
        </thead>
        <tbody>`);

    let prodData = invoiceDetails?.Itemdetails?.map((prod, index) => {
      // Check if either Discount_A or Discount_P is present
      if (prod.Discount_A) {
        printWindow.document.write(`<tr>
                  <th>${prod.Product_Name}</th>
                  <th>${prod.Quantity}</th>
                  <th>${prod.Discount_A}</th>
                  <th>
                      CGST: ${prod.Cgst}<br />
                      SGST: ${prod.Sgst}
                  </th>
                  <th>${prod.Base_Selled_Amount}</th>
              </tr>`);
      }
      else {
        printWindow.document.write(`<tr>
        <th>${prod.Product_Name}</th>
        <th>${prod.Quantity}</th>
        <th>${prod.Discount_P}</th>
        <th>
            CGST: ${prod.Cgst}<br />
            SGST: ${prod.Sgst}
        </th>
        <th>${prod.Base_Selled_Amount}</th>
    </tr>`);
      }
    });

    printWindow.document.write(`
    </tbody>
    </table>
    <div style="margin-top: 2px;, margin-bottom: 2px">
    <div><p><span> Total: `+ invoiceDetails?.Order?.Payment_Amount + `</span></p></div>
    </div>
    <div class="container" style="margin-top: 2px;, margin-bottom: 2px">
    <span class="left">
    <img height="40" src="/static/invoice_signature.png"></img>
    <div><p><span> Authorized Signatory</span></p></div>
    </span>
    <span class="right">
    <img height="120" src="/static/terms_cond_qr.png"></img>
    </span>
    </div>
    <div style="margin-top: 2px;, margin-bottom: 2px"><p><span>Note: If Undelivered return to Seller Partner Address.</span></p></div>
    </div>`);
    // printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');

    // Print the contents
    // printWindow.print();
    // Close the window
    // printWindow.close();
  }


  // const OrderLineItemStatusHandler = ordersData => e => {
  //   navigate('/app/edit-order-status-history', { state: { ordersData, ID: "1" } });
  // };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1150}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn}>Invoice</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allOrders) &&
                (allOrders).map((orders, i) => (
                  <TableRow
                    hover
                    key={i}
                    selected={selectedOrdersIds.indexOf(orders.Order_Id) !== -1}>
                    <TableCell width="10%">{orders?.Order_Id}</TableCell>
                    <TableCell width="10%">{orders?.Invoice_No}</TableCell>
                    <TableCell width="10%">{orders?.Created_Date}</TableCell>
                    <TableCell width="12%">{orders?.Full_Name}</TableCell>
                    <TableCell width="10%">{orders?.Mobile}</TableCell>
                    <TableCell width="12%">{orders?.Payment_Amount}</TableCell>
                    <TableCell width="10%">{orders?.Total_Order_Value}</TableCell>
                    <TableCell width="10%">WALK IN</TableCell>
                    <TableCell width="10%">{orders?.Payment_Type}</TableCell>
                    {/* <TableCell width="10%">{orders?.Created_By}</TableCell> */}

                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Order_History_Write?.Value === "Order_History_Write" && RolePermissions?.Order_History_Write?.Is_Write === 1) ?
                      <TableCell width="10%">
                        {/* <Button onClick={OrderLineItemStatusHandler(orders)}>
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button> */}

                        <IconButton onClick={() => OrderLineItemStatusHandler(orders.Order_Id)} color="primary" component="span">
                          <Print />
                        </IconButton>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allOrders) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Order Details</DialogTitle>
        <DialogContent>
          <p>Order ID: {selectedOrderId}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => Invoice_Get(selectedOrderId)} color="primary" variant="contained">
            Print Invoice
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allOrders: PropTypes.array.isRequired
};

export default Results;