import React, { useState, useEffect } from 'react';
import {
    Container, Typography, Grid, Card, CardContent, CardMedia, Button, Box, TextField, FormControl, InputLabel, Select, MenuItem, Pagination,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTheme } from '@emotion/react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useDispatch, useSelector } from 'react-redux';
import { paginationServicesBookings } from 'src/store/actions/node-actions/nodepagination';
import { EditPartnerServicebookingAction } from 'src/store/actions/partnerservicesbookingAction';

const ServiceBooking = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [services, setServices] = useState([]);
    const [serviceStatuses, setServiceStatuses] = useState({});
    const [pagination, setPagination] = useState({ PageNo: 1 });
    const [sortOrder, setSortOrder] = useState('desc');
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');  
    const [subService, setSubService] = useState('');
    const [zip, setZip] = useState('');
    const [date, setDate] = useState('');


    useEffect(() => {
        fetchData();
    }, [searchTerm, subService, zip, date, dispatch, pagination.PageNo, sortOrder]);
    

    const fetchData = async () => {
        let searchFor = 'All';
        let searchValue = searchTerm;

        if (subService) {
            searchFor = 'Name'; 
            searchValue = subService;
        } else if (zip) {
            searchFor = 'Zip';  
            searchValue = zip;
        } else if (date) {
            searchFor = 'Created_Datetime';  
            searchValue = date;
        }

        const formData = {
            Search: searchValue,  
            SearchFor: searchFor,
            SortOrder: sortOrder
        };

        const handleResponse = (success, results, paginationData) => {
            if (success) {
                setServices(results);
                const statuses = {};
                results.forEach(service => {
                    statuses[service.Sb_Id] = service.Service_Status;
                });
                setServiceStatuses(statuses);

                if (paginationData) {
                    setPagination(paginationData);
                }
            } else {
                console.error("Error fetching services");
            }
        };

        try {
            await dispatch(paginationServicesBookings(formData, { PageNo: pagination.PageNo }, handleResponse));
        } catch (error) {
            console.error("Error fetching services:", error);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);  
    };

    const handleSubServiceChange = (event) => {
        setSubService(event.target.value);
    };

    const handleZipChange = (event) => {
        setZip(event.target.value);
    };

    const handleDateChange = (event) => {
        setDate(event.target.value);
    };

    const handleSearchSubmit = () => {
        setPagination({ PageNo: 1 });  
    };

    const handleStatusChange = (Sb_Id, newStatus) => {
        setServiceStatuses(prevStatuses => ({
            ...prevStatuses,
            [Sb_Id]: newStatus,
        }));

        const formData = { Sb_Id, Service_Status: newStatus };
        dispatch(EditPartnerServicebookingAction(formData, navigate));
    };

    const handlePageChange = (event, newPage) => {
        setPagination(prev => ({ ...prev, PageNo: newPage }));
    };

    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
    };



    return (
        <Box>
            <Box sx={{ width: "100%", height: "100vh" }}>
                <Box>
                    <Button
                        component={RouterLink}
                        to="/app/newservices-maincentre"
                        variant="text"
                        size="small"
                        color="inherit"
                        sx={{ marginTop: "2%" }}
                    >
                        <KeyboardBackspaceIcon />
                        Go back
                    </Button>
                </Box>
                <Container>
                    <Box sx={{ display: "flex", marginTop: "2%", marginBottom: "2%" }}>
                        <Typography variant="h4" gutterBottom>
                            Booking Requests
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "15px",
                            marginBottom: "5%"
                        }}
                    >
                        <Box sx={{ width: "30%" }}>
                            <TextField 
                                id="Search" 
                                label="Search" 
                                fullWidth 
                                value={searchTerm} 
                                onChange={handleSearchChange}  
                            />
                        </Box>

                        <Box sx={{ width: "30%" }}>
                            <TextField 
                                id="Sub Services" 
                                label="Sub Services" 
                                fullWidth 
                                value={subService}
                                onChange={handleSubServiceChange}
                            />
                        </Box>

                        <Box sx={{ width: "17%" }}>
                            <TextField 
                                id="Zip" 
                                label="Zip" 
                                fullWidth 
                                value={zip}
                                onChange={handleZipChange}
                            />
                        </Box>
                        <Box sx={{ width: "15%" }}>
                            <TextField
                                fullWidth
                                type="date"
                                name="Date and Time"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                value={date}
                                onChange={handleDateChange}
                            />
                        </Box>
                    </Box>

                    <Grid container spacing={4}>
                        {services.map((service) => {
                            const scheduleDatetime = new Date(service.Schedule_Datetime);
                            const formattedTime = scheduleDatetime.toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            });
                            const day = scheduleDatetime.getDate().toString().padStart(2, '0');
                            const month = (scheduleDatetime.getMonth() + 1).toString().padStart(2, '0');
                            const year = scheduleDatetime.getFullYear();
                            const formattedDate = `${day}-${month}-${year}`;

                            const totals = Array.isArray(service.Servicevariants) 
                            ? service.Servicevariants.map(variant => {
                                const serviceCharge = parseFloat(variant.Service_Charge_For_Timeslot) || 0;
                                const quantity = parseInt(variant.Quantity, 10) || 0; 
                                return serviceCharge * quantity;
                            }) 
                            : [];
                    
                        const total = totals.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                            return (
                                <Grid item xs={12} sm={6} md={4} key={service.Sb_Id}>
                                    <Card>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={service.Image}
                                            alt="sub service image"
                                            style={{ objectFit: 'contain' }}
                                        />
                                        <CardContent>
                                            <Box display="flex" alignItems="center" mb={2}>
                                                <CalendarTodayIcon sx={{ color: 'text.secondary', mr: 1 }} />
                                                <Typography variant="body2" color="textSecondary">
                                                    {formattedDate} {formattedTime}
                                                </Typography>
                                            </Box>
                                            <Typography variant="h6" component="div">
                                                {service.Name}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                <LocationOnIcon /> {service.City} ,{service.Door_No} ,{service.Street}, {service.Land_Mark} ,{service.Zip} 
                                            </Typography>
                                            <Typography variant="body2" component="div">
                                                Number of hours: {service.Gst}
                                            </Typography>
                                            <Typography variant="body2" component="div">
                                                Price: ₹{service.Gst}/hour
                                            </Typography>
                                            <Typography variant="body2" component="div">
                                                Total: ₹{total}/-
                                            </Typography>
                                            <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="10px">
                                                <Box sx={{ width: '50%' }}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <Select
                                                            size='small'
                                                            labelId="service-status-label"
                                                            value={serviceStatuses[service.Sb_Id] || ''}
                                                            onChange={(event) => handleStatusChange(service.Sb_Id, event.target.value)}
                                                        >
                                                            {[
                                                                'ServicePlaced',
                                                                'Accepted',
                                                                'PartnerAccepted',
                                                                'PartnerAssigned',
                                                                'OnGoing',
                                                                'OutforService',
                                                                'Done',
                                                                'IN-ACTIVE',
                                                            ].map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Box sx={{ marginTop: "2%", display: "flex", justifyContent: "center" }}>
                        <Pagination
                            count={pagination.TotalPages}
                            page={pagination.PageNo}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default ServiceBooking;
