import React, { useState, useEffect } from 'react';
import {
    Box, Button, Card, CardContent, Grid, TextField, CardHeader, Divider, ListItemAvatar,
    ListItemText, Dialog, DialogTitle, IconButton, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getsingleOrderActionNew, OrderAorD, Partner_Adrs_Get, } from 'src/store/actions/ordersAction';
import { toast } from 'react-toastify'
import isEmpty from 'src/store/validations/is-empty';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography, Paper, Step, StepLabel, Stepper, } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';

const EditOrderStatusHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const classes = useStyles();
    const location = useLocation();
    const { orderId } = location.state || {};
    const Orders = useSelector(state => state?.orders?.singleorder?.all);
    const [selectedAddressId, setSelectedAddressId] = useState('');
    const [partnerAdrs, setPartnerAdrs] = useState([])
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState('');
    // const [comments, setComments] = useState('');
    const [accptopen, setaccptopen] = useState(false);

    //dispatch actions
    useEffect(() => {
        if (!isEmpty(orderId)) {
            const formData = {
                "Records_Filter": "FILTER",
                "Order_Id": orderId
            };
            dispatch(getsingleOrderActionNew(formData));
        }
    }, []);

    useEffect(() => {
        Partner_Adrs_Call(orderId);
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedAddressId('');
        setOpen(false);
    };

    const handleClickAccptOpen = () => {
        setaccptopen(true);
    };
    const handleAccptClose = () => {
        setReason('');
        setaccptopen(false);
    };

    const handleAddressChange = (event) => {
        setSelectedAddressId(event.target.value);
    };

    const Partner_Adrs_Call = async (orderId) => {
        try {
            const response = await Partner_Adrs_Get(orderId);
            if (response.status) {
                setPartnerAdrs(response.data);
            } else {
                setPartnerAdrs([]);
            }
        } catch (error) {
            setPartnerAdrs([]);
        }
    };

    //for billing address......
    const address = Orders?.User_Billing_Address_Full;
    let billAddress = null;
    if (address) {
        try {
            billAddress = JSON.parse(address);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    //for shipping address......
    const shippingAddress = Orders?.User_Shipping_Address_Full;
    let shipAddress = null;
    if (shippingAddress) {
        try {
            shipAddress = JSON.parse(shippingAddress);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    const decOrder = async (Order_Id) => {
        setLoading(true);
        const formData = {
            "Order_Status": "DECLINED",
            "Decline_Reason": reason,
            // "Reason_Info": comments,
        };
        const result = await dispatch(OrderAorD(Order_Id, formData));
        setLoading(false);
        if (result.data.code === "Success") {
            navigate('/app/cancelled-order-history')
        } else {
            toast(result.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    const acptOrder = async (Order_Id) => {
        setLoading(true);
        const formData = {
            "Order_Status": "ACCEPTED",
            "Partner_Address_Id": selectedAddressId,
        };
        const result = await dispatch(OrderAorD(Order_Id, formData, navigate));
        setLoading(false);
        if (result.data.code === "Success") {
            navigate('/app/inprogress-order-history')
            // handleClose();
        } else {
            toast(result.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    const orderDates = Orders?.Order_Status_Dates_Json
    const OrderStepper = ({ orderDates }) => {
        return (
            <Stepper orientation="vertical" style={{ padding: '20px' }}>
                {orderDates && Object.entries(orderDates).map(([key, { Date, BY, ID }]) => (
                    <Step key={key} active>
                        <StepLabel>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '8px' }}>
                                {/* <CalendarTodayIcon color="primary" /> */}
                                <strong>Status: {key}</strong>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <CalendarTodayIcon fontSize="small" />
                                <span>Date: {Date}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <PersonIcon fontSize="small" />
                                <span>By: {BY}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <BadgeIcon fontSize="small" />
                                <span>ID: {ID}</span>
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        );
    };

    return (
        <>
            <Card>
                <CardHeader title="Order Details" />

                <Box display="flex" style={{ marginTop: "-50px" }} justifyContent="flex-end" p={1}>
                    {(Orders?.Payment_Transaction_Status === "FAIL") || (Orders?.Payment_Transaction_Status === "PENDING") ?
                        <></>
                        :
                        (<Button
                            style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                            variant="contained"
                            onClick={handleClickAccptOpen}
                        >
                            <span style={{ color: 'white' }}>ACCEPT ORDER</span>
                        </Button>)
                    }

                    <Button
                        style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                        variant="contained"
                        onClick={handleClickOpen}
                    >
                        <span style={{ color: 'white' }}>DECLINE ORDER</span>
                    </Button>
                </Box>

                <Divider />

                <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                    {/* Order Information */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Order Information
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                                <Typography variant="body1" style={{ marginRight: '1rem' }}>ID: {Orders?.Order_Id}</Typography>
                                {Orders?.Exchange_Reference_Retail_Order_Id && (
                                    <Typography variant="body1" style={{ fontWeight: 'bold', color: 'orange' }}>EXCHANGED ORDER</Typography>
                                )}
                            </div>
                            <Typography variant="body2">
                                Status: <span style={{ fontWeight: 'bold' }}>{Orders?.Order_Status}</span>
                            </Typography>
                            <Typography variant="body2">User Email ID: {Orders?.Email_Id}</Typography>
                            <Typography variant="body2">Partner ID: {Orders?.Partner_Detail_Id}</Typography>
                            <Typography variant="body2">Partner Name: {Orders?.Name}</Typography>
                            {/* <Typography variant="body2">Created Date: {Orders?.Created_Date}</Typography>
                            <Typography variant="body2">Modified Date: {Orders?.Modified_Date}</Typography> */}

                            {Orders?.Partner_Product_Id &&
                                <Typography variant="body2">Partner Product Id: {Orders?.Partner_Product_Id}</Typography>}

                            {Orders?.Gift_History_Id &&
                                <Typography variant="body2">Gift History Id: {Orders?.Gift_History_Id}</Typography>}

                            {Orders?.Gift_Wrap_Message &&
                                <Typography variant="body2">Gift Wrap Msg: {Orders?.Gift_Wrap_Message}</Typography>}

                            <OrderStepper orderDates={orderDates} />

                        </Paper>
                    </Grid>

                    {/* Billing Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Billing Details
                            </Typography>
                            <Typography variant="body2">Address ID: {billAddress?.USER_ADDRESS_ID}</Typography>
                            <Typography variant="body2">Email ID: {billAddress?.USER_EMAIL_ID}</Typography>
                            <Typography variant="body2">Address Type: {billAddress?.ADDRESS_TYPE}</Typography>
                            <Typography variant="body2">Landmark: {billAddress?.LAND_MARK}</Typography>
                            <Typography variant="body2">City: {billAddress?.CITY}</Typography>
                            <Typography variant="body2">State: {billAddress?.STATE}</Typography>
                            <Typography variant="body2">Country: {billAddress?.COUNTRY}</Typography>
                            <Typography variant="body2">ZIP: {billAddress?.ZIP}</Typography>
                        </Paper>
                    </Grid>

                    {/* Payment Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Payment Details
                            </Typography>
                            <Typography variant="body2">Total Order Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Total_Order_Value}</span></Typography>
                            <Typography variant="body2">Total Tax Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Total_Tax}</span></Typography>
                            <Typography variant="body2">VAT Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Vat}</span></Typography>
                            <Typography variant="body2">Payment Method: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Type}</span></Typography>
                            <Typography variant="body2">Payment Reference ID: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Transaction_Id}</span></Typography>
                            <Typography variant="body2">Sgst: <span style={{ fontWeight: 'bold' }}>{Orders?.Sgst}</span></Typography>
                            <Typography variant="body2">Wallet Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_Amount}</span></Typography>
                            <Typography variant="body2">Wallet History Id: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_History_Id}</span></Typography>

                            {Orders?.Exchange_Reference_Retail_Order_Id ?
                                <span style={{ fontWeight: 'bold', color: 'green' }}>Payment Status : SUCCESS</span>
                                :
                                <Typography variant="body2" style={{ color: Orders?.Payment_Transaction_Status === "SUCCESS" ? 'green' : 'red' }}>
                                    Payment Status: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Transaction_Status === "SUCCESS" ? "SUCCESS" : "FAIL"}</span>
                                </Typography>
                            }
                            {Orders?.Partner_Settlement_Amount &&
                                <Typography variant="body2">Partner Settlement Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Partner_Settlement_Amount}</span></Typography>}
                            {Orders?.Gift_Wrap_Amount &&
                                <Typography variant="body2">Gift Wrap Amount: {Orders?.Gift_Wrap_Amount}</Typography>}
                            <Typography variant="body2">Gift Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Gift_Amount}</span></Typography>
                            <Typography variant="body2">Wallet Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_Amount}</span></Typography>
                            <Typography variant="body2">Shipping Fee: <span style={{ fontWeight: 'bold' }}>{Orders?.Shipping_Amount}</span></Typography>
                            <Typography variant="body2">Cashback Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Cashback}</span></Typography>
                            <Typography variant="body2">Transaction Id: <span style={{ fontWeight: 'bold' }}>{Orders?.Transaction_Id}</span></Typography>
                            <Typography variant="body2">Partner Coupon Amount: {Orders?.Partner_Coupon_Amount}</Typography>

                        </Paper>
                    </Grid>

                    {/* Shipping Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Shipping Details
                            </Typography>
                            <Typography variant="body2">Address ID: {shipAddress?.USER_ADDRESS_ID}</Typography>
                            <Typography variant="body2">Email ID: {shipAddress?.USER_EMAIL_ID}</Typography>
                            <Typography variant="body2">Address Type: {shipAddress?.ADDRESS_TYPE}</Typography>
                            <Typography variant="body2">Landmark: {shipAddress?.LAND_MARK}</Typography>
                            <Typography variant="body2">City: {shipAddress?.CITY}</Typography>
                            <Typography variant="body2">State: {shipAddress?.STATE}</Typography>
                            <Typography variant="body2">Country: {shipAddress?.COUNTRY}</Typography>
                            <Typography variant="body2">ZIP: {shipAddress?.ZIP}</Typography>
                        </Paper>
                    </Grid>

                    {/* Product Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Product Details
                            </Typography>
                            <Typography variant="body2">Product Name: <span style={{ fontWeight: 'bold' }}>{Orders.Product_Name}</span></Typography>
                            <Typography variant="body2">Quantity: <span style={{ fontWeight: 'bold' }}>{Orders.Quantity}</span></Typography>
                            <Typography variant="body2">Price Per Quantity: <span style={{ fontWeight: 'bold' }}>{Orders.Quantity_Selling_Price}</span></Typography>
                            <Typography variant="body2">Selling Price: <span style={{ fontWeight: 'bold' }}>{Orders.Base_Selling_Price}</span></Typography>
                            <Typography variant="body2">MRP: <span style={{ fontWeight: 'bold' }}>{Orders.Mrp}</span></Typography>
                            <Typography variant="body2">Size: <span style={{ fontWeight: 'bold' }}>{Orders.Size} {Orders.Size_Measurement}</span></Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <Divider />

                {/* for decling the order */}
                <Dialog open={open}>
                    <DialogTitle>Decline Order</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Sure to decline this order - {orderId} ?
                        </DialogContentText>

                        <TextField
                            autoFocus
                            margin="dense"
                            id="reason"
                            label="Reason for decline"
                            type="text"
                            fullWidth
                            style={{ width: '400px' }}
                            required
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            variant="outlined"
                        />
                        {/* <TextField
                            margin="dense"
                            id="comments"
                            label="Reason comments"
                            type="text"
                            fullWidth
                            multiline
                            rows={4}
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            variant="outlined"
                        /> */}
                    </DialogContent>
                    <DialogActions>
                        {/* Cancel Button */}
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>

                        {reason &&
                            <Button
                                style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                                variant="contained"
                                onClick={() => decOrder(Orders?.Order_Id)}
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircularProgress size={24} style={{ color: 'white' }} />
                                ) : (
                                    <span style={{ color: 'white' }}>Decline</span>
                                )}
                            </Button>
                        }
                    </DialogActions>
                </Dialog>

                {/* for accptng the order */}
                <Dialog open={accptopen}>
                    <DialogTitle>Accept Order</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Sure to accept this order - {orderId} ?
                        </DialogContentText>
                        {/* Address Dropdown */}
                        <FormControl fullWidth variant="outlined" style={{ marginTop: '16px' }}>
                            <InputLabel>Select Address</InputLabel>
                            <Select
                                value={selectedAddressId}
                                onChange={handleAddressChange}
                                label="Select Address"
                                style={{ width: '400px' }}
                            >
                                {partnerAdrs?.map((address) => (
                                    <MenuItem key={address.Partner_Address_Id} value={address.Partner_Address_Id}>
                                        {address.Door_No}, {address.Street}, {address.City_Name}, {address.State}, {address.Zip}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAccptClose} color="primary">
                            Cancel
                        </Button>
                        {selectedAddressId &&
                            <Button
                                style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                                variant="contained"
                                onClick={() => acptOrder(Orders?.Order_Id)}
                            >
                                {/* <span style={{ color: 'white' }}>Accept</span> */}
                                {loading ? (
                                    <CircularProgress size={24} style={{ color: 'white' }} /> // Spinner inside the button
                                ) : (
                                    <span style={{ color: 'white' }}>Accept</span>
                                )}
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </Card>

        </>
    )
}

export default EditOrderStatusHistory;
