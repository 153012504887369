import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Table, TableBody, TableCell, TableHead, TableRow, Typography, Divider, FormControl, MenuItem, Select, TextField } from '@mui/material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { paginationServicesBookings } from 'src/store/actions/node-actions/nodepagination';
import { useDispatch } from 'react-redux';

const ServicesBookingDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [services, setServices] = useState([]);
    const { Sb_Id } = useParams();
    const [serviceStatuses, setServiceStatuses] = useState({});

    useEffect(() => {
        fetchData(Sb_Id);
    }, [Sb_Id]);

    const fetchData = async () => {
        const handleResponse = (success, results, paginationData) => {
            if (success) {
                setServices(results);
                const initialStatuses = results.reduce((acc, service) => {
                    acc[service.Sb_Id] = service.Service_Status || '';
                    return acc;
                }, {});
                setServiceStatuses(initialStatuses);
            } else {
                console.error("Error fetching services");
            }
        };
        try {
            await dispatch(paginationServicesBookings({ Sb_Id }, {}, handleResponse));
        } catch (error) {
            console.error("Error fetching services:", error);
        }
    };

    const handleStatusChange = (serviceId, newStatus) => {
        setServiceStatuses((prevStatuses) => ({
            ...prevStatuses,
            [serviceId]: newStatus,
        }));
    };



    return (
        <Box sx={{ width: "100%", height: "100vh" }}>
            <Box>
                <Button
                    component={RouterLink}
                    to="/app/ServiceBooking-management"
                    variant="text"
                    size="small"
                    color="inherit"
                    sx={{ marginTop: "2%" }}
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
            </Box>
            <Box p={4} bgcolor="#f5f6fa">
                {/* Header Section */}
                <Box display="flex" flexDirection="column" mb={2}>
                    {services?.map((service, index) => (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                            key={index}
                        >
                            {/* Booking Details */}
                            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
                                    <Typography variant="h5">Booking details</Typography>
                                    <Box mb={2}>
                                        <Typography variant="subtitle1">Booking ID: {service?.Sb_Id}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Booking placed on: {service?.Created_Datetime}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Scheduled date & time: {service?.Schedule_Datetime}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Service location: {service?.User_Address_Id}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box sx={{ width: "50%", display: "flex", justifyContent: "flex-end" }}>
                                    <TextField
                                        size="small"
                                        label="Service Status"
                                        value={serviceStatuses[service.Sb_Id] || ""}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Divider />

                <Box mt={2}>
                    <Typography variant="h6">Service Details</Typography>
                    <Card>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Service variant</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Qty</TableCell>
                                    <TableCell>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {services.map((service, index) => {
                                    const subServiceName = service.Name;
                                    const svNames = service.Servicevariants?.map(variant => variant.Sv_Name) || [];
                                    const quantities = service.Servicevariants?.map(variant => variant.Quantity) || [];
                                    const serviceAmount = service.Servicevariants?.map(variant => variant.Service_Charge_For_Timeslot) || [];
                                    const total = quantities.map((quantity, idx) => quantity * serviceAmount[idx]);
                                    const totalServiceAmount = total.reduce((acc, curr) => acc + curr, 0);

                                    const gstPercentage = service.Gst || 0;
                                    const gstAmount = (totalServiceAmount * gstPercentage) / 100;

                                    return (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell>
                                                    {svNames.map((name, idx) => (
                                                        <Box
                                                            key={idx}
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                whiteSpace: "nowrap",
                                                                textWrap: "nowrap"
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{ height: "auto", width: "100%", fontWeight: '500' }}
                                                                noWrap
                                                            >
                                                                {name}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </TableCell>
                                                <TableCell>
                                                    {serviceAmount.map((amount, idx) => (
                                                        <Box
                                                            key={idx}
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                whiteSpace: "nowrap",
                                                                textWrap: "nowrap"
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{ height: "auto", width: "100%", fontWeight: '500' }}
                                                                noWrap
                                                            >
                                                                ₹{amount}/-
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </TableCell>
                                                <TableCell>
                                                    {quantities.map((quantity, idx) => (
                                                        <Box
                                                            key={idx}
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                whiteSpace: "nowrap",
                                                                textWrap: "nowrap"
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{ height: "auto", width: "100%", fontWeight: '500' }}
                                                                noWrap
                                                            >
                                                                {quantity}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </TableCell>
                                                <TableCell>
                                                    {total.map((totals, idx) => (
                                                        <Box
                                                            key={idx}
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                whiteSpace: "nowrap",
                                                                textWrap: "nowrap"
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{ height: "auto", width: "100%", fontWeight: '500' }}
                                                                noWrap
                                                            >
                                                                ₹{totals}/-
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </TableCell>
                                            </TableRow>
                                            {index < services.length - 1 && (
                                                <TableRow>
                                                    <TableCell colSpan={4}>
                                                        <Divider />
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    );
                                })}

                            </TableBody>
                        </Table>

                        <Box width={{ xs: '100%', sm: '33%', md: "95%" }} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Box p={2}>
                                <Typography variant="h6">Order Summary</Typography>

                                <Box display="flex" justifyContent="space-between" mt={1}>
                                    <Typography>Service amount</Typography>
                                    <Typography ml={5}>
                                        ₹{services.reduce((acc, service) => {
                                            const quantities = service.Servicevariants?.map(variant => variant.Quantity) || [];
                                            const serviceAmount = service.Servicevariants?.map(variant => variant.Service_Charge_For_Timeslot) || [];
                                            const total = quantities.map((quantity, idx) => quantity * serviceAmount[idx]);
                                            return acc + total.reduce((acc, curr) => acc + curr, 0);
                                        }, 0)}/-
                                    </Typography>
                                </Box>

                                <Box display="flex" justifyContent="space-between" mt={1}>
                                    <Typography>Tax (GST)</Typography>
                                    <Typography ml={5}>
                                        ₹{services.reduce((acc, service) => {
                                            const totalServiceAmount = service.Servicevariants?.reduce((sum, variant) => sum + (variant.Quantity * variant.Service_Charge_For_Timeslot), 0) || 0;
                                            const gstAmount = (totalServiceAmount * (service.Gst || 0)) / 100;
                                            return acc + gstAmount;
                                        }, 0)}/-
                                    </Typography>
                                </Box>

                                <Divider sx={{ my: 1 }} />

                                <Box display="flex" justifyContent="space-between">
                                    <Typography>Total</Typography>
                                    <Typography ml={5}>
                                        ₹{services.reduce((acc, service) => {
                                            const quantities = service.Servicevariants?.map(variant => variant.Quantity) || [];
                                            const serviceAmount = service.Servicevariants?.map(variant => variant.Service_Charge_For_Timeslot) || [];
                                            const total = quantities.map((quantity, idx) => quantity * serviceAmount[idx]);
                                            const totalServiceAmount = total.reduce((acc, curr) => acc + curr, 0);
                                            const gst = (totalServiceAmount * (service.Gst || 0)) / 100; // Calculate GST
                                            const commission = service.Comission_Amount || 0; // Commission amount
                                            return acc + totalServiceAmount + gst + commission;
                                        }, 0)}/-
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
};

export default ServicesBookingDetails;
