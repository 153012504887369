import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  InputLabel,
  Card,
  CardContent,
  Grid,
  TextField,
  Avatar,
  FormControl,
  makeStyles,
  Select,
  MenuItem,
  Button,
  TextareaAutosize
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { VarientuploadImagepath } from 'src/store/actions/servicesvarientAction';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { EditPartnerServiceRequestAction } from 'src/store/actions/servicepartnerRequestAction';
import { useNavigate, useLocation } from 'react-router';
import {
  paginationsubcategoryServices,
  paginationServicescategory
} from 'src/store/actions/node-actions/nodepagination';
import { ConnectedFocusError } from "focus-formik-error";
import CircularProgress from '@mui/material/CircularProgress';
import { paginationrequestServices } from 'src/store/actions/node-actions/nodepagination';

const StatusOption = [
  {
      value: '',
      label: '-- Please Select --'
  },
  {
      value: 'success',
      label: 'success'
  },
  {
      value: 'pending',
      label: 'pending'
  },
  {
    value: 'hold',
    label: 'hold'
  },
  {
    value: 'rejected',
    label: 'rejected'
  }
];


const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  Description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditServiceRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  let { state } = location;
  const [image, setImage] = useState({ preview: state.Image, raw: state.Image });
  const [imgUrl, setImgUrl] = useState(state.Image);
  const [uploadPic, setUploadPic] = useState(false); 
  const [errorMessage, setErrorMessage] = useState({ Request_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  // const serviceData = location.state?.serviceData || {};
  const [Requests, setRequest] = useState([]);
  const [services, setServices] = useState([]);
  const isSubmitted = useSelector(
    state => state.category.categoryUpdate.isLoading
  );
  const categories = useSelector(state => state.subcategory.Categories.categories);

  // Populate the initialValues with serviceData
  const initialValues = {
    // Image: serviceData.Image,
    // type: serviceData.Request_Type,
    // serviceName: serviceData.Service_Name,
    // subServiceName: serviceData.Ss_Name,
    // variantName: serviceData.Request_Name,
    // Status: serviceData.Status_Feedback,
    // description: serviceData.Description,
    Image: imgUrl,
    Request_Id: state.Request_Id? state.Request_Id: '',
    Request_Type: state.Request_Type? state.Request_Type: '',
    Request_Name: state.Request_Name? state.Request_Name: '',
    Description: state.Description ? state.Description : '',
    Status: state.Status ? state.Status : '',
    Status_Feedback: state.Status_Feedback ? state.Status_Feedback : '',
    Service_Name: state.Service_Name ? state.Service_Name : "",
    Service_Id: state.Service_Id ? state.Service_Id : "",
    Ss_Name: state.Ss_Name ? state.Ss_Name : "",
    Ss_Id: state.Ss_Id ? state.Ss_Id : "",
  };

  const handleChange = (event, setFieldValue) => {
    setFieldValue("type", event.target.value);
  };

  

  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = (e) => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    // Simulate async image upload
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      VarientuploadImagepath(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = () => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  useEffect(() => {
    // Fetch services
    dispatch(
      paginationrequestServices({}, {}, (status, data) => {
        if (status) {
          setServices(data);
        }
      })
    );
 
    if (state.Service_Id) {
      dispatch(
        paginationsubcategoryServices({ Service_Id: state.Service_Id }, {}, (status, data) => {
          if (status) {
            setRequest(data);
          }
        })
      );
    }
  }, [state.Service_Id, dispatch]);
  


  // Yup validation schema
  const validationSchema = Yup.object().shape({
    Request_Type: Yup.string().required('Type is required'),
    serviceName: Yup.string().when('type', {
      is: 'Services',
      then: Yup.string().required('Service Name is required')
    }),
    subServiceName: Yup.string().when('type', {
      is: 'Sub Services',
      then: Yup.string().required('Sub Service Name is required')
    }),
    variantName: Yup.string().when('type', {
      is: 'Varients',
      then: Yup.string().required('Variant Name is required')
    }),
    Status_Feedback: Yup.string().required('Status is required'),
    Description: Yup.string().required('Description is required'),
  })
  const onSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(false);
    let submitTest = true;
    const errors = {};
    values.Image = imgUrl;
    
    if (!values.Image) {
      errors.Image = "Image is required.";
      submitTest = false;
    } 
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      dispatch(EditPartnerServiceRequestAction(formValues, navigate, setErrorMessage, setErrors));
    }
  }

  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    // onSubmit={(values) => {
    //   const payload = {
    //     Request_Type: values.Request_Type,
    //     Request_Name: values.Request_Name,
    //     Description: values.Request_Type,
    //     Image: imgUrl, 
    //     Status_Feedback: values.Status_Feedback
    //   };
    //   dispatch(EditPartnerServiceRequestAction(payload));
    // }}
    >
      {({ handleSubmit,handleBlur, handleChange, setFieldValue, errors, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box>
                <Grid container spacing={4}>
                  {/* Form Fields - Left Side */}
                  <Grid item xs={12} md={8}>
                    <Box>
                      <Box
                        sx={{
                          minWidth: 120,
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: 4
                        }}
                      >
                        {/* Type Select */}
                        <Box sx={{ minWidth: '50%' }}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id=" type">Type</InputLabel>
                            <Select
                              labelId="type"
                              id="type-select"
                              value={values.Request_Type}
                              label="Type"
                              onChange={(e) => handleChange(e, setFieldValue)}
                              name="type"
                            >
                              <MenuItem value="Services">Services</MenuItem>
                              <MenuItem value="Sub Services">Sub Services</MenuItem>
                              <MenuItem value="Variants">Variants</MenuItem>
                            </Select>
                            {errors.Request_Type && touched.Request_Type && (
                              <div style={{ color: 'red' }}>{errors.Request_Type}</div>
                            )}
                          </FormControl>
                        </Box>

                        {/* Conditionally Rendered Fields */}
                        {values.Request_Type === 'Services' && (
                          <Box sx={{ flex: 1 }}>
                            <TextField
                              id="serviceName"
                              label="Service Name"
                              variant="outlined"
                              fullWidth
                              name="serviceName"
                              onChange={handleChange}
                            />
                            {errors.Request_Name && touched.Request_Name && (
                              <div style={{ color: 'red' }}>{errors.Request_Name}</div>
                            )}
                          </Box>
                        )}
                        {values.Request_Type === 'Sub Services' && (
                          <Box sx={{ flex: 1 }}>
                            <TextField
                              id="subServiceName"
                              label="Sub Service Name"
                              variant="outlined"
                              fullWidth
                              name="subServiceName"
                              onChange={handleChange}
                            />
                            {errors.Request_Name && touched.Request_Name && (
                              <div style={{ color: 'red' }}>{errors.Request_Name}</div>
                            )}
                          </Box>
                        )}
                        {values.Request_Type === 'Variants' && (
                          <Box sx={{ flex: 1 }}>
                            <TextField
                              id="variantName"
                              label="Variant Name"
                              variant="outlined"
                              fullWidth
                              name="variantName"
                              value = {values.Request_Name}
                              onChange={handleChange}
                            />
                            {errors.Request_Name && touched.Request_Name && (
                              <Box style={{ color: 'red' }}>{errors.Request_Name}</Box>
                            )}
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          minWidth: 120,
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: 4,
                          marginTop: 12,
                          marginBottom: "12px"
                        }}>

                        {(values.Request_Type === 'Sub Services' || values.Request_Type === 'Variants') && (
                          <Box sx={{ flex: 1, minWidth: '50%' }}>
                            <TextField
                        error={Boolean(touched.Service_Id && errors.Service_Id)}
                        fullWidth
                        helperText={touched.Service_Id && errors.Service_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Service Name"
                        name="Service_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={services.length > 0 ? values.Service_Id : ""}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {services?.sort((a, b) => a.Service_Name?.localeCompare(b.Service_Name))?.map(option => (
                          <option key={option.Service_Id} value={option.Service_Id}>
                            {option.Service_Name}
                          </option>
                        ))}
                      </TextField>
                          </Box>
                        )}

                        {values.Request_Type === 'Variants' && (
                          <Box sx={{ flex: 1, minWidth: '50%' }}>
                             <TextField
                        error={Boolean(touched.Ss_Id && errors.Ss_Id)}
                        fullWidth
                        helperText={touched.Ss_Id && errors.Ss_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Sub Service Name"
                        name="Ss_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={Requests.length > 0 ? values.Ss_Id : ""}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {Requests?.sort((a, b) => a.Ss_Name?.localeCompare(b.Ss_Name))?.map(option => (
                          <option key={option.Ss_Id} value={option.Ss_Id}>
                            {option.Ss_Name}
                          </option>
                        ))}
                      </TextField>
                          </Box>
                        )}

                      </Box>

                      {/* Description Field */}
                      <Grid item md={12} xs={12}>
                        <Box>
                        <TextareaAutosize
                          className={classes.Description}
                          // aria-label="description"
                          placeholder="Description"
                          minRows={10}
                          name="Description"
                          value={values.Description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        </Box>
                        {errors.Description && touched.Description && (
                          <Box style={{ color: 'red' }}>{errors.Description}</Box>
                        )}
                      </Grid>

                      {/* Status Feedback Field */}
                      <Grid item md={12} xs={12}>
                        <Box>
                        <FormControl fullWidth variant="outlined" style={{ marginTop: '10px' }}>
                        <TextareaAutosize
                          className={classes.Status_Feedback}
                          style={{ padding: '10px' }}
                          placeholder="Status Feedback"
                          minRows={10}
                          name="Status_Feedback"
                          value={values.Status_Feedback}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        </FormControl>
                        </Box>
                        {errors.Status_Feedback && touched.Status_Feedback && (
                          <Box style={{ color: 'red' }}>{errors.Status_Feedback}</Box>
                        )}
                      </Grid>

                      {/* Status Field */}
                      <Grid item md={12} xs={12}>
                        <FormControl fullWidth variant="outlined" style={{ marginTop: '10px' }}>
                        <TextField
                          error={Boolean(touched.Status && errors.Status)}
                          fullWidth
                          helperText={touched.Status && errors.Status}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Status"
                          name="Status"
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ native: true }}
                          value={values.Status}
                          variant="outlined"
                        >
                         {StatusOption.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                        </FormControl>
                      </Grid>
                    </Box>
                  </Grid>

                  {/* Form Fields - Right Side */}
                  <Grid item md={4} xs={12}>
                    <Card>
                      &nbsp;Web Image
                      <CardContent>
                        <Box display="flex" justifyContent="center">
                          {image.preview ? (
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src={image.preview}
                              variant="square"
                            />
                          ) : (
                            <>
                              <Avatar
                                className={classes.images}
                                alt="Brand Upload"
                                src="/static/images/image-placeholder.png"
                                variant="square"
                              />
                            </>
                          )}
                        </Box>

                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          {image.preview ? ( <Button
                            style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                            onClick={handleRemoveImage}
                          >
                            Remove Picture
                          </Button> ): (<input
                            style={{ display: 'block', width: '80%' }}
                            id="upload-photo"
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={handleImage}
                          />)}
                          <br />
                        </Box>
                      </CardContent>
                    </Card>
                    <span style={{ color: "red" }}>
                      {!imgUrl && touched.Image && errors.Image}
                    </span>




                  </Grid>
                </Grid>
                  <Box display="flex" pl={2} >
                    <span style={{ color: "red" }}>{errorMessage.global.message}</span>
                  </Box>

                {/* Submit Button */}
                <Box display="flex" marginTop={4}>
                {isSubmitted ? (
                <CircularProgress />
              ) : (

                  <Button 
                   disabled={isSubmitted || uploadPic}
                  type="submit" 
                  variant="contained" 
                  style={{ backgroundColor: '#27B6CC' }}>
                    <span style={{ color: 'white' }}>Submit</span>

                  </Button>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditServiceRequest.propTypes = {
  className: PropTypes.string
};

export default EditServiceRequest;