import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { ConnectedFocusError } from "focus-formik-error";
import CircularProgress from '@mui/material/CircularProgress';
import {
    // EditServicesPartnerManagementAction,
    categoryuploadImagepath
} from '../../../store/actions/servicecategoriesAction';
import { TextareaAutosize } from '@mui/material';
import { EditServicesPartnerManagementAction } from 'src/store/actions/servicespartnermanagementAction';
import { paginationPartnermanagementServices, paginationsubcategoryServices } from 'src/store/actions/node-actions/nodepagination';


const StatusOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'ACTIVE',
        label: 'ACTIVE'
    },
    {
        value: 'IN-ACTIVE',
        label: 'IN-ACTIVE'
    }
];

const useStyles = makeStyles(() => ({
    images: {
        width: "100%",
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    Partner_Details_Id: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const EditServicePartnerManagement = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;
    const [subcategories, setSubcategorires] = useState([]);
    const [errorMessage, setErrorMessage] = useState({ Ss_Id: { key: '', message: '' }, global: { key: '', message: '' } });
    const isSubmitted = useSelector(state => state.category.categoryUpdate.isLoading);
    const modifiedBy = useSelector(state => state.auth.user.Email_Id);
    const [partnerservice, setpartnerservice] = useState([])

    useEffect(() => {
        dispatch(paginationsubcategoryServices({}, {}, (status, data) => {
            if (status) {
                setSubcategorires(data)
            }
            else
                setSubcategorires([])
        }));
    }, []);

    useEffect(() => {
        dispatch(paginationPartnermanagementServices({}, {}, (Status, data) => {
          if (Status) {
            setpartnerservice(data)
          }
          else
            setpartnerservice([])
        }));
      }, []);
    


    return (
        <Formik
            initialValues={{
                Pss_Id: state.Pss_Id ? state.Pss_Id : "",
                Ss_Id: state.Ss_Id ? state.Ss_Id : "",
                Partner_Details_Id: state.Partner_Details_Id ? state.Partner_Details_Id : "",
                Status: state.Status ? state.Status : "",
                // Modified_By: modifiedBy,

            }}
            validationSchema={
                Yup.object().shape({
                    Ss_Id: Yup.string().min(3, "sub service Name must be at least 3 characters.").notOneOf([errorMessage.Ss_Id.key, null], errorMessage.Ss_Id.message).required('sub service Name is required.'),
                    Status: Yup.string().required('Status is required.'),
                    Partner_Details_Id: Yup.string().min(10, 'Partner_Details_Id must be at least 10 characters.').required('Partner_Details_Id is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};

                setErrors(errors);
                if (submitTest) {

                    dispatch(EditServicesPartnerManagementAction(values, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            <Grid item md={12} xs={12}>
                                                <Box>
                                                    <TextField
                                                        error={Boolean(touched.Partner_Details_Id && errors.Partner_Details_Id)}
                                                        fullWidth
                                                        helperText={touched.Partner_Details_Id && errors.Partner_Details_Id}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        label="Partner Name"
                                                        name="Partner_Details_Id"
                                                        select
                                                        InputLabelProps={{ shrink: true }}
                                                        SelectProps={{ native: true }}
                                                        value={values.Partner_Details_Id}
                                                        variant="outlined"
                                                    >
                                                        <option key="" value="">
                                                            --Please Select--
                                                        </option>
                                                        {partnerservice
                                                            ?.sort((a, b) => a.Name?.localeCompare(b.Name))
                                                            ?.map((option, index) => (
                                                                <option key={index} value={option.Partner_Details_Id}>
                                                                    {option.Name}
                                                                 
                                                                </option>
                                                            ))}
                                                    </TextField>
                                                </Box>

                                            </Grid>

                                            
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Box>
                                            <TextField
                                                error={Boolean(touched.Ss_Id && errors.Ss_Id)}
                                                fullWidth
                                                helperText={touched.Ss_Id && errors.Ss_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Sub service Name"
                                                name="Ss_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Ss_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">
                                                    --Please Select--
                                                </option>
                                                {subcategories
                                                    ?.sort((a, b) => a.Ss_Name?.localeCompare(b.Ss_Name))
                                                    ?.map((option, index) => (
                                                        <option key={index} value={option.Ss_Id}>
                                                            {option.Ss_Name}
                                                        </option>
                                                    ))}
                                            </TextField>
                                            </Box>
                                            </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {StatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>


                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    {/* Save details */}
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditServicePartnerManagement.propTypes = {
    className: PropTypes.string
};

export default EditServicePartnerManagement;