import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate, } from 'react-router-dom';
import {
  Typography, AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar, List, ListItem, Divider,
  makeStyles, SwipeableDrawer,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import "../../views/view-couponnotification/index.scss"
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import Logo from 'src/components/Logo';
import { loginOut } from 'src/store/actions/authActions';
import "../DashboardLayout/TopBar.scss";
import { getAllNotificationAction, UpdateNotificationAction } from '../../store/actions/notificationAction';
import { useDispatch, useSelector } from "react-redux";
import { getSingleCoupon } from '../../store/actions/couponAction';
import { getSingleDeal } from '../../store/actions/dealAction';
import { getSingleProduct } from '../../store/actions/productAction';
import { getSingleOrderAction } from '../../store/actions/ordersAction';
import { getsingleOrderAction } from '../../store/actions/ordersAction';
import { getSingleb2bOrderAction } from '../../store/actions/b2borderAction';
import { getsingleB2BOrderAction } from '../../store/actions/b2borderAction';
import { getSingleUserGift } from '../../store/actions/usergifthistoryAction';
import { getSingleReferandEarn } from '../../store/actions/referandearnAction';
import { getUserTicketAction } from '../../store/actions/ticketingActions';
import { getPartnerTicketAction } from '../../store/actions/partnerticketActions';
import { getSinglePartnerAction } from '../../store/actions/partnerAction';
import { getPendingPartnerAction } from '../../store/actions/partnerAction';
import { getUserProfileAction } from '../../store/actions/userprofileAction';
import { getSingleDeliveryWalletAction } from '../../store/actions/deliverywalletAction';
import { getMissingcashbackAction } from '../../store/actions/missingcashbackAction';
import { getSinglePartnerWallet } from '../../store/actions/partnerwalletAction';
import { getUserWalletAction } from '../../store/actions/walletAction';
import { getDeliveryTicketAction } from '../../store/actions/deliveryticketActions';
import { getDeliveryAgentAction } from '../../store/actions/deliveryAgentAction';
import { getDeliveryAgentRegApprovalAction } from '../../store/actions/deliveryAgentAction';
import { getPartnerAddressAction } from '../../store/actions/partneraddressAction';
import { getUserRideAction } from '../../store/actions/userridesAction';
import { getUserServiceAction } from '../../store/actions/servicesAction';
import { getPartnerServiceAction } from '../../store/actions/partnerserviceAction';
import { getPartnerGenericServicesAction } from '../../store/actions/servicepartnercityAction';
import { getSingleBrand } from '../../store/actions/brandAction';
import { getCityAction } from '../../store/actions/sbcmCityAction';
import { getInventoryAction } from '../../store/actions/inventoryAction';
import { getPartnerEmployeeAction } from '../../store/actions/partneremployeeAction';
import { getUserRideFareAction } from '../../store/actions/userridefaresAction';
import { getPartnerServiceCityDealAction } from '../../store/actions/citypartnerdealAction';
import { getPartnerServiceCityCouponAction } from '../../store/actions/citypartnercouponAction';
import { getPartnerServiceCityProductAction } from '../../store/actions/sbcmProductAction';
import { getPartnerProductAction } from '../../store/actions/productpartnerAction';
import { getMyCompanyProfileAction } from '../../store/actions/mycompanyprofileAction';
import { getBankdetailsAction } from '../../store/actions/partnerbankdetailsAction';
import { getGiftVoucherVendorAction } from '../../store/actions/giftvendorAction';
import { getUserCommentAction } from '../../store/actions/commentsAction';
import { getDeliveryCommentAction } from '../../store/actions/deliverycommentsActions';
import { getWalletHistoryAction } from '../../store/actions/wallethistoryAction';
import { getPartnerWalletHistoryAction } from '../../store/actions/partnerwallethistoryAction';
import { getDeliveryWalletHistoryAction } from '../../store/actions/deliverywalletAction';
import { getDeliveryVehicleAction } from '../../store/actions/deliveryvehicledetailsAction';
import { getPartnerDeliveryAgentAction } from '../../store/actions/partnerdeliveryagentsAction';
import { getPartnerVehicleDataAction } from '../../store/actions/partnervehicledetailsAction';
import { getServiceCityPartnerAction } from '../../store/actions/citypartnerAction';
import { getgiftvochersAction } from '../../store/actions/giftAction';
import { getUsersignupAction } from '../../store/actions/userAction';
import { getProductAction } from '../../store/actions/productAction';
import { getPartnerServiceCityAction } from '../../store/actions/partnercitylocationAction';
import { getBiddingUserProductAction } from '../../store/actions/biddinguserproductsAction';
import { getBiddingUserRideAction } from '../../store/actions/userridebiddingsAction';
import { getBiddingUserServiceAction } from '../../store/actions/biddinguserserviceAction';
import { getBiddingProductsQuoteCommentsAction } from '../../store/actions/partnerbiddingcommentsAction';
import { getBiddingProductsQuoteAction } from '../../store/actions/partnerbiddingquotesAction';
import { getBiddingServiceQuoteAction } from '../../store/actions/partnerbiddingservicequoteAction';
import { getBiddingServicesQuoteCommentsAction } from '../../store/actions/partnerbiddingservicequotecommentsAction';
import { getBiddingRideQuoteAction } from '../../store/actions/deliverybiddingquotesAction';
import { getBiddingRideCommentsAction } from '../../store/actions/deliverybiddingcommentsAction';
import { getBiddingAvailProductAction } from '../../store/actions/bidpartneravailproductsAction';
import { getBiddingVehicleBrandAction } from '../../store/actions/biddingvehiclebrandsAction';
import { getBiddingAvailServiceAction } from '../../store/actions/biddingpartneravailableserviceAction';
import { getB2BBiddingUserProductsAction } from '../../store/actions/b2buserproductsbiddingAction';
import { getB2BBiddingProductQuoteAction } from '../../store/actions/b2buserprodbidquotesAction';
import { getB2BBiddingProductCommentAction } from '../../store/actions/b2buserprodbidcommentsAction';
import PropTypes from 'prop-types';
import isEmpty from 'src/store/validations/is-empty';
import NotificationSound from '../../assets/meepaisa_notification.wav';
import NotificationOrderSound from '../../assets/Order.mp3';
// import { getNotificationsLoadmore } from 'src/store/actions/node-actions/nodepagination';


const TopBar = ({ className, onMobileNavOpen, anchor, ...rest }) => {
  const user = useSelector((state) => state.auth.user);
  const [reload, setReloading] = React.useState(false);
  const navigate = useNavigate();
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);
  const Logout = (e) => {
    e.preventDefault();
    dispatch(loginOut(navigate, user))
  }

  const drawerBleeding = 56;

  const useStyles = makeStyles(() => ({
    root: {
      height: 60,
      backgroundColor: "#27B6CC"
    },
    avatar: {
      width: 60,
      height: 60
    },
    content: {
      textAlign: 'center',
      marginTop: -30
    },
    time: {
      marginTop: "5px",
      color: '#4C8EF7 ',
      marginLeft: "280px",
    }
  }));


  // Notifications  
  const classes = useStyles();
  const dispatch = useDispatch()
  const [totalcount, setTotalcount] = useState()
  const [notifications, setNotifications] = useState([]);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  const audioPlayer = useRef(null);
  const audioPlayerorder = useRef(null);

  function playAudio() {
    audioPlayer.current.play();
  }
  function playAudioOrder() {
    audioPlayerorder.current.play();
  }
  function pouseAudioOrder() {
    audioPlayerorder.current.muted = true;
  }
  const callBackData = (data) => {
    if (data) {
      if (data?.sound === "1") {
        setNotifications(data)
        if ((data.results[0]?.Type === 'Orders') ||
          (data.results[1]?.Type === 'Orders')) {
          playAudioOrder()
        }
        // if (data.results[0]?.Type === 'Orders' && data.results[0].Is_Super_Admin_Read == '1'){
        //   playAudioOrder()
        // }
        else if (data.results[0]?.Type === "B2B Order") {
          playAudio()
        }
        else if (data.results[0]?.Type === "Shopper_Orders") {
          playAudio()
        }
        else if (data.results[0]?.Type === "Partner Ticket") {
          playAudio()
        }
        else if (data.results[0]?.Type === "Delivery Ticket Comment") {
          playAudio()
        }
        else if (data.results[0]?.Type === "User Ticket Comment") {
          playAudio()
        }
        else if (data.results[0]?.Type === "Partner SignUp") {
          playAudio()
        }
        else if (data.results[0]?.Type === "Delivery Ticket") {
          playAudio()
        }
        else if (data.results[0]?.Type === "User Ticket") {
          playAudio()
        }
        else if (data.results[0]?.Type === "Ticket") {
          playAudio()
        }
        else if (data.results[0]?.Type === "Ticket Comment") {
          playAudio()
        }
        else if (data.results[0]?.Type === "Delivery Signup") {
          playAudio()
        }
        else if (data.results[0]?.Type === "Missing Cashback") {
          playAudio()
        }
        setTotalcount(data.notification_total_count)
      } else {
        setNotifications(data)
        setTotalcount(data.notification_total_count)
      }
    }
  }

  // get notification
  useEffect(() => {
    if (seconds == 0) {
      setSeconds(60)
      let formData = {
        "Count": totalcount,
        "Records_Filter": "ALL"
      }
      // setReloading(false);
      dispatch(getAllNotificationAction(formData, callBackData))
    }
  }, [seconds])

  const callBackCouponData = (data) => {
    if (data) {
      navigate('/app/couponview-details', { state: data.results[0] });
    }
  }
  const callBackDealData = (data) => {
    if (data) {
      navigate('/app/deals-details', { state: data.results[0] });
    }
  }
  const callBackProductData = (data) => {
    if (data) {
      navigate('/app/view-products', { state: data.results[0] });
    }
  }
  const callBackorderData = (data) => {
    if (data) {
      navigate('/app/edit-order-status-history', { state: data?.Orders?.Order_Data[0] });
    }
  }
  const callBackb2borderData = (data) => {
    if (data) {
      navigate('/app/b2b-order-update', { state: data.Orders.Order_Data[0] });
    }
  }
  const callBackUserGiftData = (data) => {
    if (data) {
      navigate('/app/view-usergifthistory', { state: data.results[0] });
    }
  }

  const callBackReferandEarnData = (data) => {
    if (data) {
      navigate('/app/refer-earn-history', { state: data.results[0] });
    }
  }

  const callBackTicketData = (data) => {
    if (data) {
      navigate('/app/ticketing-management-view', { state: data.results[0] });
    }
  }
  const callBackPartnerTicketData = (data) => {
    if (data) {
      navigate('/app/partner-ticketing-management-view', { state: data.results[0] });
    }
  }
  const callBackPartnerData = (data) => {
    if (data) {
      navigate('/app/partner-management-history', { state: data.results[0] });
    }
  }
  const callBackProfileData = (data) => {
    if (data) {
      navigate('/app/view-user-profile', { state: data.results[0] });
    }
  }
  const callBackDeliveryWalletData = (data) => {
    if (data) {
      navigate('/app/view-delivery-wallet', { state: data.results[0] });
    }
  }
  const callBackMissingCashbackData = (data) => {
    if (data) {
      navigate('/app/view-missingcashback', { state: data.results[0] });
    }
  }
  const callBackPartnerWalletData = (data) => {
    if (data) {
      navigate('/app/view-partner-wallet', { state: data.results[0] });
    }
  }
  const callBackWalletData = (data) => {
    if (data) {
      navigate('/app/view-wallet', { state: data.results[0] });
    }
  }
  const callBackDeliveryTicketData = (data) => {
    if (data) {
      navigate('/app/delivery-ticketing-management-view', { state: data.results[0] });
    }
  }
  const callBackDeliveryAgentData = (data) => {
    if (data) {
      navigate('/app/view-approved-delivery-agent-details', { state: data.Del_Registration_Approval_Response[0] });
    }
  }
  const callBackPartnerAddressData = (data) => {
    if (data) {
      navigate('/app/partneraddress-view', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackUserRideData = (data) => {
    if (data) {
      navigate('/app/user-rides-view', { state: data.results[0] });
    }
  }
  const callBackUserServiceData = (data) => {
    if (data) {
      navigate('/app/view-services', { state: data.results[0] });
    }
  }
  const callBackPartnerServiceData = (data) => {
    if (data) {
      navigate('/app/view-partnerservices', { state: data.results[0] });
    }
  }
  const callBackPartnerGenericServicesData = (data) => {
    if (data) {
      navigate('/app/view-genericservices', { state: data.results[0] });
    }
  }
  const callBackBrandData = (data) => {
    if (data) {
      navigate('/app/brand-details', { state: data.results[0] });
    }
  }
  const callBackCityData = (data) => {
    if (data) {
      navigate('/app/view-citydetails', { state: data.results[0] });
    }
  }
  const callBackInventoryData = (data) => {
    if (data) {
      navigate('/app/inventory-history', { state: data.results[0] });
    }
  }
  const callBackPartnerEmployeeData = (data) => {
    if (data) {
      navigate('/app/view-partner-employee', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackUserRideFareData = (data) => {
    if (data) {
      navigate('/app/user-ride-fares-view', { state: data.results[0] });
    }
  }
  const callBackPartnerServiceCityDealData = (data) => {
    if (data) {
      navigate('/app/view-partnercitydeal', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackPartnerServiceCityCouponData = (data) => {
    if (data) {
      navigate('/app/view-partnercitycoupon', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackPartnerServiceCityProductData = (data) => {
    if (data) {
      navigate('/app/view-partnercityproduct', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackPendingPartnerData = (data) => {
    if (data) {
      navigate('/app/view-partnerapproval', { state: data.results[0] });
    }
  }
  const callBackPartnerProductData = (data) => {
    if (data) {
      navigate('/app/view-partner-product', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackCompanyProfileData = (data) => {
    if (data) {
      navigate('/app/mycompanyprofile', { state: data.results[0] });
    }
  }
  const callBackpartnerbank = (data) => {
    if (data) {
      navigate('/app/partnerbank-details', { state: data.results[0] });
    }
  }
  const callBackGiftVoucherVendorData = (data) => {
    if (data) {
      navigate('/app/view-giftvendor', { state: data.results[0] });
    }
  }
  const callBackUserCommentData = (data) => {
    if (data) {
      navigate('/app/user-comments-details', { state: data.results[0] });
    }
  }
  const callBackDeliveryCommentData = (data) => {
    if (data) {
      navigate('/app/delivery-comments-details', { state: data.results[0] });
    }
  }
  const callBackWalletHistoryData = (data) => {
    if (data) {
      navigate('/app/view-wallet-history', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackPartnerWalletHistoryData = (data) => {
    if (data) {
      navigate('/app/view-partnerwallethistory', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackDeliveryWalletHistoryData = (data) => {
    if (data) {
      navigate('/app/view-delivery-wallethistory', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackDeliveryVehicleData = (data) => {
    if (data) {
      navigate('/app/view-vehicledetails', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackPartnerDeliveryAgentData = (data) => {
    if (data) {
      navigate('/app/view-partner-deliveryagent', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackPartnerVehicleData = (data) => {
    if (data) {
      navigate('/app/view-partnervehicledetails', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackDeliveryAgentRegApprovalData = (data) => {
    if (data) {
      navigate('/app/view-pending-delivery-agent-details', { state: data.results[0] });
    }
  }
  const callBackServiceCityPartnerData = (data) => {
    if (data) {
      navigate('/app/view-partnerservicecity', { state: data.results[0] });
    }
  }
  const callBackGiftVocherData = (data) => {
    if (data) {
      navigate('/app/view-usergifthistory', { state: data.results[0] });
    }
  }
  const callBacksignupData = (data) => {
    if (data) {
      navigate('/app/view-user-managment', { state: data.results[0] });
    }
  }
  const callBackBiddingProductsData = (data) => {
    if (data) {
      navigate('/app/view-user-bidding-products', { state: data.results[0] });
    }
  }

  const callBackBiddingRidesData = (data) => {
    if (data) {
      navigate('/app/view-user-ride-bidding', { state: data.results[0] });
    }
  }

  const callBackProductsData = (data) => {
    if (data) {
      navigate('/app/view-productapproval', { state: data.results[0] });
    }
  }
  const callBackPartnerServiceCityData = (data) => {
    if (data) {
      navigate('/app/view-city-partner-location', { state: { state: data.results[0], data: data } });
    }
  }

  const callBackBiddingProductsQuote = (data) => {
    if (data) {
      navigate('/app/partner-bidding-quote-view', { state: { state: data.results[0], data: data } });
    }
  }

  const callBackBiddingAvailService = (data) => {
    if (data) {
      navigate('/app/view-partner-bidding-available-services', { state: data.results[0] });
    }
  }

  const callBackBiddingVehicleBrand = (data) => {
    if (data) {
      navigate('/app/bidding-vehicle-brand-view', { state: data.results[0] });
    }
  }

  const callBackBiddingAvailProduct = (data) => {
    if (data) {
      navigate('/app/partner-bidding-available-products-view', { state: data.results[0] });
    }
  }

  const callBackB2BBiddingProducts = (data) => {
    if (data) {
      navigate('/app/view-b2b-user-products-bidding', { state: data.results[0] });
    }
  }
  const callBackB2BBiddingProductQuote = (data) => {
    if (data) {
      navigate('/app/view-b2b-user-product-bidding-quote', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackBiddingServiceQuote = (data) => {
    if (data) {
      navigate('/app/view-partner-bidding-service-quotes', { state: { state: data.results[0], data: data } });
    }
  }
  const callBackBiddingRideQuote = (data) => {
    if (data) {
      navigate('/app/view-delivery-bidding-quote', { state: { state: data.results[0], data: data } });
    }
  }

  const callBackBiddingServiceData = (data) => {
    if (data) {
      navigate('/app/view-bidding-user-services', { state: data.results[0] });
    }
  }
  const notificationData = (notification) => {
    // console.log(notification, 'nothdkjjjjjjjjjjjjjjjjjjjjjjjj')
    setReloading(true);
    let formData = {
      Module: "SUPER_ADMIN",
      Notification_Id: notification.Notification_Id,
    }
    dispatch(UpdateNotificationAction(formData));
    if (notification.Type === "Coupon") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Coupon_Id: notification.Coupon_Id
      }
      navigate('/app/couponview-details');
      dispatch(getSingleCoupon(formdata, callBackCouponData))
    }
    if (notification.Type === "Coupon_Update") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Coupon_Id: notification.Coupon_Id
      }
      navigate('/app/couponview-details');
      dispatch(getSingleCoupon(formdata, callBackCouponData))
    }
    else if (notification.Type === "Coupons") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Coupon_Id: notification.Coupon_Id
      }
      navigate('/app/couponview-details');
      dispatch(getSingleCoupon(formdata, callBackCouponData))
    }
    else if (notification.Type === "Partner Coupons") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Coupon_Id: notification.Coupon_Id
      }
      navigate('/app/couponview-details');
      dispatch(getSingleCoupon(formdata, callBackCouponData))
    }
    else if (notification.Type === "Product") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Product_Id: notification.Product_Id
      }
      navigate('/app/view-products');
      dispatch(getSingleProduct(formdata, callBackProductData))
    }
    else if (notification.Type === "Super_Admin_Product") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Product_Id: notification.Product_Id
      }
      navigate('/app/view-products');
      dispatch(getSingleProduct(formdata, callBackProductData))
    }
    else if (notification.Type === "Physical Partner Product") {
      if (notification.Partner_Product_Id) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Product_Id: notification.Partner_Product_Id,
          Product_Id: notification.Product_Id
        }
        navigate('/app/view-partner-product');
        dispatch(getPartnerProductAction(formdata, callBackPartnerProductData))
      }
    }
    else if (notification.Type === "Partner_Physical_Product_Create") {
      if (notification.Partner_Product_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Partner_Product_Id: notification.Partner_Product_Id,
          Product_Id: notification.Product_Id
        }
        navigate('/app/view-partner-product');
        dispatch(getPartnerProductAction(formdata, callBackPartnerProductData))
      }
    }
    else if (notification.Type === "Partner Physical Product") {
      if (notification.Partner_Product_Id) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Product_Id: notification.Partner_Product_Id,
          Product_Id: notification.Product_Id
        }
        navigate('/app/view-partner-product');
        dispatch(getPartnerProductAction(formdata, callBackPartnerProductData))
      }
    }
    else if (notification.Type === "Partner Product") {
      if (notification.Partner_Product_Id) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Product_Id: notification.Partner_Product_Id,
        }
        navigate('/app/view-partner-product');
        dispatch(getPartnerProductAction(formdata, callBackPartnerProductData))
      }
    }
    else if (notification.Type === "Physical_Partner_Product_Update") {
      if (notification.Partner_Product_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Partner_Product_Id: notification.Partner_Product_Id,
          Product_Id: notification.Product_Id
        }
        navigate('/app/view-partner-product');
        dispatch(getPartnerProductAction(formdata, callBackPartnerProductData))
      }
    }
    else if (notification.Type === "Partner_Products_Create") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Product_Id: notification.Product_Id
      }
      navigate('/app/view-productapproval');
      dispatch(getSingleProduct(formdata, callBackProductData))
    }
    else if (notification.Type === "Physical Product") {
      if (notification.Partner_Product_Id) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Product_Id: notification.Partner_Product_Id,
        }
        navigate('/app/view-partner-product');
        dispatch(getPartnerProductAction(formdata, callBackPartnerProductData))
      }
    }
    else if (notification.Type === "Deal") {
      let formdata = {
        Records_Filter: "FILTER",
        Deal_Id: notification.Deal_Id,
        search_by_filter: "",
        search: "",
      }
      dispatch(getSingleDeal(formdata, callBackDealData))
    }
    else if (notification.Type === "Partner Deals") {
      let formdata = {
        Records_Filter: "FILTER",
        Deal_Id: notification.Deal_Id,
        search_by_filter: "",
        search: "",
      }
      dispatch(getSingleDeal(formdata, callBackDealData))
    }
    else if (notification.Type === "Deals") {
      let formdata = {
        Records_Filter: "FILTER",
        Deal_Id: notification.Deal_Id,
        search_by_filter: "",
        search: "",
      }
      dispatch(getSingleDeal(formdata, callBackDealData))
    }
    else if (notification.Type === "USER_RETAIL_NEW_ORDER") {
      let formdata = {
        Records_Filter: "FILTER",
        Order_Id: notification.Order_Id
      }
      // navigate('/app/edit-order-status-history', {state: {notification, ID: "1"}});
      navigate('/app/edit-order-status-history', { state: { orderId: notification.Order_Id } });
      dispatch(getsingleOrderAction(formdata));
    }
    else if (notification.Type === "USER_RETAIL_NEW_ORDER_RETURN") {
      let formdata = {
        Records_Filter: "FILTER",
        Order_Id: notification.Order_Id
      }
      navigate('/app/edit-rtrnd-order-status-history', { state: { orderId: notification.Order_Id } });
      dispatch(getsingleOrderAction(formdata));
    }
    else if (notification.Type === "USER_RETAIL_NEW_ORDER_EXCHANGE") {
      let formdata = {
        Records_Filter: "FILTER",
        Order_Id: notification.Order_Id
      }
      navigate('/app/edit-rtrnd-order-status-history', { state: { orderId: notification.Order_Id } });
      dispatch(getsingleOrderAction(formdata));
    }
    else if (notification.Type === "RETAIL_NEW_ORDER_SHIPPED") {
      let formdata = {
        Records_Filter: "FILTER",
        Order_Id: notification.Order_Id
      }
      navigate('/app/edit-inprgs-order-status-history', { state: { orderId: notification.Order_Id } });
      dispatch(getsingleOrderAction(formdata));
    }
    else if (notification.Type === "RETAIL_NEW_ORDER_READY_FOR_SHIPPING") {
      let formdata = {
        Records_Filter: "FILTER",
        Order_Id: notification.Order_Id
      }
      navigate('/app/edit-inprgs-order-status-history', { state: { orderId: notification.Order_Id } });
      dispatch(getsingleOrderAction(formdata));
    }
    else if (notification.Type === "Partner_Order_Update") {
      let formdata = {
        Records_Filter: "FILTER",
        Order_Id: notification.Order_Id
      }
      navigate('/app/edit-order-status-history', { state: { notification, ID: "1" } });
      dispatch(getsingleOrderAction(formdata));
    }
    else if (notification.Type === "Partner Order") {
      let formdata = {
        Records_Filter: "FILTER",
        Order_Id: notification.Order_Id
      }
      navigate('/app/edit-order-status-history', { state: { notification, ID: "1" } });
      dispatch(getsingleOrderAction(formdata));
    }
    else if (notification.Type === "Orders") {
      const formData = {
        Records_Filter: "FILTER",
        Order_Id: notification.Order_Id
      };
      navigate('/app/edit-order-status-history', { state: { notification, ID: "1" } });
      dispatch(getsingleOrderAction(formData));
    }

    else if (notification.Type === "Super Admin Order") {
      let formData = {
        Records_Filter: "FILTER",
        Order_Id: notification.Order_Id
      }
      navigate('/app/edit-order-status-history', { state: { notification, ID: "1" } });
      // dispatch(getSingleOrderAction(formdata, callBackorderData))
      dispatch(getsingleOrderAction(formData));
    }
    else if (notification.Type === "Super Admin B2B User Order") {
      let formdata = {
        Records_Filter: "FILTER",
        Shopper_Order_Id: notification.Shopper_Order_Id
      }
      navigate('/app/b2b-order-update');
      dispatch(getsingleB2BOrderAction(formdata))
    }
    else if (notification.Type === "Shopper_Orders") {
      let formdata = {
        Records_Filter: "FILTER",
        Shopper_Order_Id: notification.Order_Id
      }
      navigate('/app/b2b-order-update');
      dispatch(getsingleB2BOrderAction(formdata))
    }
    else if (notification.Type === "B2B Order") {
      let formdata = {
        Records_Filter: "FILTER",
        Shopper_Order_Id: notification.Order_Id
      }
      navigate('/app/b2b-order-update');
      dispatch(getsingleB2BOrderAction(formdata))
    }
    else if (notification.Type === "Gift Voucher Vendor") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Vendor_Id: notification.Gift_Vendor_Id
      }
      navigate('/app/view-giftvendor');
      dispatch(getGiftVoucherVendorAction(formdata, callBackGiftVoucherVendorData))
    }
    else if (notification.Type === "Partner_Gift_Updated") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Gift_Voucher_Id: notification.Gift_Voucher_Id
      }
      navigate('/app/view-usergifthistory');
      dispatch(getgiftvochersAction(formdata, callBackGiftVocherData))
    }
    else if (notification.Type === "Gift Vendor Partner") {
      let formdata = {
        Records_Filter: "FILTER",
        Vendor_Id: notification.Gift_Vendor_Id,
        search_by_filter: "",
        search: "",
      }
      navigate('/app/view-giftvendor');
      dispatch(getGiftVoucherVendorAction(formdata, callBackGiftVoucherVendorData))
    }
    else if (notification.Type === "Super Admin Gift Vendor") {
      let formdata = {
        Records_Filter: "FILTER",
        Vendor_Id: notification.Gift_Vendor_Id,
        search_by_filter: "",
        search: "",
      }
      navigate('/app/view-giftvendor');
      dispatch(getGiftVoucherVendorAction(formdata, callBackGiftVoucherVendorData))
    }
    else if (notification.Type === "User Gift") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Gift_Voucher_Id: notification.Gift_Voucher_Id
      }
      navigate('/app/view-usergifthistory');
      dispatch(getgiftvochersAction(formdata, callBackGiftVocherData))
    }
    else if (notification.Type === "Partner Gift") {
      let formdata = {
        Records_Filter: "FILTER",
        Gift_Voucher_Id: notification.Gift_Voucher_Id
      }
      navigate('/app/view-usergifthistory');
      dispatch(getgiftvochersAction(formdata, callBackGiftVocherData))
    }
    else if (notification.Type === "Refer&Earn") {
      let formdata = {
        search: '',
        search_by_filter: '',
        Records_Filter: "FILTER",
        Reference_Id: notification.Reference_Id
      }
      navigate('/app/refer-earn-history');
      dispatch(getSingleReferandEarn(formdata, callBackReferandEarnData))
    }
    else if (notification.Type === "User Ticket") {
      let formdata = {
        search: '',
        search_by_filter: '',
        Records_Filter: "FILTER",
        Ticket_Id: notification.Ticket_Id
      }
      navigate('/app/ticketing-management-view');
      dispatch(getUserTicketAction(formdata, callBackTicketData))
    }
    else if (notification.Type === "Partner Ticket") {
      let formdata = {
        search: '',
        search_by_filter: '',
        Records_Filter: "FILTER",
        Ticket_Id: notification.Ticket_Id
      }
      navigate('/app/ticketing-management-view');
      dispatch(getUserTicketAction(formdata, callBackTicketData))
    }
    else if (notification.Type === "Ticket") {
      let formdata = {
        Records_Filter: "FILTER",
        Ticket_Id: notification.Ticket_Id
      }
      navigate('/app/partner-ticketing-management-view');
      dispatch(getPartnerTicketAction(formdata, callBackPartnerTicketData))
    }
    else if (notification.Type === "callBackPartnerDataPartner Physical Profile") {
      let formdata = {
        Records_Filter: "FILTER",
        Partner_Details_Id: notification.Partner_Detail_Id
      }
      navigate('/app/partner-management-history');
      dispatch(getSinglePartnerAction(formdata, callBackPartnerData))
    }
    else if (notification.Type === "Partner Profile") {
      let formdata = {
        search: '',
        search_by_filter: '',
        Records_Filter: "FILTER",
        Partner_Details_Id: notification.Partner_Detail_Id
      }
      navigate('/app/partner-management-history');
      dispatch(getSinglePartnerAction(formdata, callBackPartnerData))
    }
    else if (notification.Type === "Super Admin Partner Profile") {
      let formdata = {
        search: "",
        search_by_filter: "",
        Records_Filter: "FILTER",
        Partner_Details_Id: notification.Partner_Detail_Id
      }
      navigate('/app/partner-management-history');
      dispatch(getSinglePartnerAction(formdata, callBackPartnerData))
    }
    else if (notification.Type === "Physical Partner") {
      let formdata = {
        search: "",
        search_by_filter: "",
        Records_Filter: "FILTER",
        Partner_Details_Id: notification.Partner_Detail_Id
      }
      navigate('/app/partner-management-history');
      dispatch(getSinglePartnerAction(formdata, callBackPartnerData))
    }
    else if (notification.Type === "User Profile") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Details_Id: notification.User_Details_Id
      }
      navigate('/app/view-user-profile');
      dispatch(getUserProfileAction(formdata, callBackProfileData))
    }
    else if (notification.Type === "User_Profile") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Details_Id: notification.User_Details_Id
      }
      navigate('/app/view-user-profile');
      dispatch(getUserProfileAction(formdata, callBackProfileData))
    }
    else if (notification.Type === "Delivery Wallet") {
      let formdata = {
        Records_Filter: "FILTER",
        Wallet_Id: notification.Wallet_Id,
        search_by_filter: "",
        search: "",
      }
      navigate('/app/view-delivery-wallet');
      dispatch(getSingleDeliveryWalletAction(formdata, callBackDeliveryWalletData))
    }
    else if (notification.Type === "Missing Cashback") {
      let formdata = {
        Records_Filter: "FILTER",
        Missing_Cashback_Id: notification.Missing_Cashback_Id,
        Status: "",
        User_Email_Id: "",
        search_by_filter: "",
        search: "",
      }
      navigate('/app/view-missingcashback');
      dispatch(getMissingcashbackAction(formdata, callBackMissingCashbackData))
    }
    else if (notification.Type === "Partner Wallet") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Wallet_Id: notification.Wallet_Id
      }
      navigate('/app/view-partner-wallet');
      dispatch(getSinglePartnerWallet(formdata, callBackPartnerWalletData))
    }
    else if (notification.Type === "Partner Withdraw Request") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Wallet_Id: notification.Wallet_Id
      }
      navigate('/app/view-partner-wallet');
      dispatch(getSinglePartnerWallet(formdata, callBackPartnerWalletData))
    }
    else if (notification.Type === "User Wallet") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Wallet_Id: notification.Wallet_Id
      }
      navigate('/app/view-wallet');
      dispatch(getUserWalletAction(formdata, callBackWalletData))
    }
    else if (notification.Type === "Wallet") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Wallet_Id: notification.Wallet_Id
      }
      navigate('/app/view-wallet');
      dispatch(getUserWalletAction(formdata, callBackWalletData))
    }
    else if (notification.Type === "Delivery Ticket") {
      let formdata = {
        Records_Filter: "FILTER",
        Del_Ticket_Id: notification.Del_Ticket_Id,
        search_by_filter: "",
        search: "",
      }
      navigate('/app/delivery-ticketing-management-view');
      dispatch(getDeliveryTicketAction(formdata, callBackDeliveryTicketData))
    }
    else if (notification.Type === "Super Admin Delivery Agent") {
      let formdata = {
        Records_Filter: "FILTER",
        Del_user_email_id: notification.Del_User_Email_Id,
        Del_User_Detail_Id: notification.Del_User_Detail_Id,
        search_by_filter: "",
        search: "",
      }
      navigate('/app/view-approved-delivery-agent-details');
      dispatch(getDeliveryAgentAction(formdata, callBackDeliveryAgentData))
    }
    else if (notification.Type === "Super_Admin_Delivery_Agent") {
      let formdata = {
        Records_Filter: "FILTER",
        Del_user_email_id: notification.Del_User_Email_Id
      }
      navigate('/app/view-approved-delivery-agent-details');
      dispatch(getDeliveryAgentAction(formdata, callBackDeliveryAgentData))
    }
    else if (notification.Type === "Delivery User Profile") {
      let formdata = {
        Records_Filter: "FILTER",
        Del_user_email_id: notification.Del_User_Email_Id
      }
      navigate('/app/view-approved-delivery-agent-details');
      dispatch(getDeliveryAgentAction(formdata, callBackDeliveryAgentData))
    }
    else if (notification.Type === "Delivery Registration Approval") {
      let formdata = {
        Records_Filter: "FILTER",
        Email_ID: notification.Del_User_Email_Id,
      }
      navigate('/app/view-approved-delivery-agent-details');
      dispatch(getDeliveryAgentAction(formdata, callBackDeliveryAgentData))
    }

    else if (notification.Type === "Super Admin Partner Address") {
      if (notification.PARTNER_ADDRESS_ID) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Address_Id: notification.PARTNER_ADDRESS_ID
        }
        navigate('/app/partneraddress-view');
        dispatch(getPartnerAddressAction(formdata, callBackPartnerAddressData))
      }
    }
    else if (notification.Type === "Partner Address") {
      if (notification.PARTNER_ADDRESS_ID) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Address_Id: notification.PARTNER_ADDRESS_ID
        }
        navigate('/app/partneraddress-view');
        dispatch(getPartnerAddressAction(formdata, callBackPartnerAddressData))
      }
    }
    else if (notification.Type === "User Ride") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Ride_Id: notification.User_Ride_Id
      }
      navigate('/app/user-rides-view');
      dispatch(getUserRideAction(formdata, callBackUserRideData))
    }
    else if (notification.Type === "Ride") {
      let formdata = {
        Records_Filter: "FILTER",
        User_Ride_Id: notification.User_Ride_Id
      }
      navigate('/app/user-rides-view');
      dispatch(getUserRideAction(formdata, callBackUserRideData))
    }
    else if (notification.Type === "Del User Ride") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Ride_Id: notification.User_Ride_Id
      }
      navigate('/app/user-rides-view');
      dispatch(getUserRideAction(formdata, callBackUserRideData))
    }
    else if (notification.Type === "User_Ride_Create") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Ride_Id: notification.User_Ride_Id
      }
      navigate('/app/user-rides-view');
      dispatch(getUserRideAction(formdata, callBackUserRideData))
    }
    else if (notification.Type === "Delivery User Ride") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Ride_Id: notification.User_Ride_Id
      }
      navigate('/app/user-rides-view');
      dispatch(getUserRideAction(formdata, callBackUserRideData))
    }
    else if (notification.Type === "Super Admin User Service") {
      let formdata = {
        Records_Filter: "FILTER",
        User_Service_request_Id: notification.User_Service_Request_Id
      }
      navigate('/app/view-services');
      dispatch(getUserServiceAction(formdata, callBackUserServiceData))
    }
    else if (notification.Type === "Partner User Service Request") {
      let formdata = {
        search: "",
        search_by_filter: "",
        Records_Filter: "FILTER",
        User_Service_request_Id: notification.User_Service_Request_Id
      }
      navigate('/app/view-services');
      dispatch(getUserServiceAction(formdata, callBackUserServiceData))
    }
    else if (notification.Type === "Partner User Service") {
      let formdata = {
        search: "",
        search_by_filter: "",
        Records_Filter: "FILTER",
        User_Service_request_Id: notification.User_Service_Request_Id
      }
      navigate('/app/view-services');
      dispatch(getUserServiceAction(formdata, callBackUserServiceData))
    }
    else if (notification.Type === "Super_Admin_User_Service_Update") {
      let formdata = {
        search: "",
        search_by_filter: "",
        Records_Filter: "FILTER",
        User_Service_request_Id: notification.User_Service_Request_Id
      }
      navigate('/app/view-services');
      dispatch(getUserServiceAction(formdata, callBackUserServiceData))
    }
    else if (notification.Type === "User Service") {
      let formdata = {
        search: "",
        search_by_filter: "",
        Records_Filter: "FILTER",
        User_Service_request_Id: notification.User_Service_Request_Id
      }
      navigate('/app/view-services');
      dispatch(getUserServiceAction(formdata, callBackUserServiceData))
    }
    else if (notification.Type === "Partner Service") {
      let formdata = {
        Records_Filter: "FILTER",
        search: "",
        search_by_filter: "",
        Partner_Service_Id: notification.PARTNER_SERVICE_ID
      }
      navigate('/app/view-partnerservices');
      dispatch(getPartnerServiceAction(formdata, callBackPartnerServiceData))
    }
    else if (notification.Type === "User Service Partner Generic") {
      let formdata = {
        Records_Filter: "FILTER",
        Partner_Service_Generic_Id: notification.PARTNER_SERVICE_GENERIC_ID
      }
      navigate('/app/view-genericservices');
      dispatch(getPartnerGenericServicesAction(formdata, callBackPartnerGenericServicesData))
    }
    else if (notification.Type === "Super Admin Brand") {
      let formdata = {
        Records_Filter: "FILTER",
        Brand_Id: notification.BRAND_ID,
        search_by_filter: "",
        search: "",
      }
      navigate('/app/brand-details');
      dispatch(getSingleBrand(formdata, callBackBrandData))
    }
    else if (notification.Type === "Brand") {
      let formdata = {
        Records_Filter: "FILTER",
        Brand_Id: notification.BRAND_ID,
        search_by_filter: "",
        search: "",
      }
      navigate('/app/brand-details');
      dispatch(getSingleBrand(formdata, callBackBrandData))
    }
    else if (notification.Type === "Partner Brand") {
      let formdata = {
        Records_Filter: "FILTER",
        Brand_Id: notification.BRAND_ID,
        search_by_filter: "",
        search: "",
      }
      navigate('/app/brand-details');
      dispatch(getSingleBrand(formdata, callBackBrandData))
    }
    else if (notification.Type === "Inventory") {
      let formdata = {
        Records_Filter: "FILTER",
        Inventory_Id: notification.Inventory_Id,
        search_by_filter: "",
        search: "",
      }
      navigate('/app/inventory-history');
      dispatch(getInventoryAction(formdata, callBackInventoryData))
    }
    else if (notification.Type === "Inventory Update") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Inventory_Id: notification.Inventory_Id
      }
      navigate('/app/inventory-history');
      dispatch(getInventoryAction(formdata, callBackInventoryData))
    }
    else if (notification.Type === "Partner Inventory") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Inventory_Id: notification.Inventory_Id
      }
      navigate('/app/inventory-history');
      dispatch(getInventoryAction(formdata, callBackInventoryData))
    }
    else if (notification.Type === "Partner_Employee_Create") {
      if (notification.Partner_Employee_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Partner_Employee_Id: notification.Partner_Employee_Id,
          Partner_Details_Id: notification.Partner_Detail_Id
        }
        navigate('/app/view-partner-employee');
        dispatch(getPartnerEmployeeAction(formdata, callBackPartnerEmployeeData))
      }
    }
    else if (notification.Type === "Partner_Employee_Update") {
      if (notification.Partner_Employee_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Partner_Employee_Id: notification.Partner_Employee_Id,
          Partner_Details_Id: notification.Partner_Detail_Id
        }
        navigate('/app/view-partner-employee');
        dispatch(getPartnerEmployeeAction(formdata, callBackPartnerEmployeeData))
      }
    }
    else if (notification.Type === "Super_Admin_Partner_Employee_Create") {
      if (notification.Partner_Employee_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Partner_Employee_Id: notification.Partner_Employee_Id,
          Partner_Details_Id: notification.Partner_Detail_Id
        }
        navigate('/app/view-partner-employee');
        dispatch(getPartnerEmployeeAction(formdata, callBackPartnerEmployeeData))
      }
    }
    else if (notification.Type === "User Ride Vehicle Fare") {
      let formdata = {
        Records_Filter: "FILTER",
        User_Ride_Vehicle_Fare_Id: notification.USER_RIDE_VEHICLE_FARE_ID
      }
      navigate('/app/user-ride-fares-view');
      dispatch(getUserRideFareAction(formdata, callBackUserRideFareData))
    }
    else if (notification.Type === "Service City Deal") {
      if (notification.Partner_Service_City_Deal_Id) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Service_City_Deal_Id: notification.Partner_Service_City_Deal_Id,
          Partner_Details_Id: notification.Partner_Detail_Id,
          City_Id: notification.CITY_ID,
          Partner_Service_City_Id: notification.PARTNER_SERVICE_CITY_ID
        }
        navigate('/app/view-partnercitydeal');
        dispatch(getPartnerServiceCityDealAction(formdata, callBackPartnerServiceCityDealData))
      }
    }
    else if (notification.Type === "Partner Service City Deal") {
      if (notification.Partner_Service_City_Deal_Id) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Service_City_Deal_Id: notification.Partner_Service_City_Deal_Id,
          Partner_Details_Id: notification.Partner_Detail_Id,
          City_Id: notification.CITY_ID,
          Partner_Service_City_Id: notification.PARTNER_SERVICE_CITY_ID
        }
        navigate('/app/view-partnercitydeal');
        dispatch(getPartnerServiceCityDealAction(formdata, callBackPartnerServiceCityDealData))
      }
    }
    else if (notification.Type === "Partner Service City Deals") {
      if (notification.Partner_Service_City_Deal_Id) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Service_City_Deal_Id: notification.Partner_Service_City_Deal_Id,
          Partner_Details_Id: notification.Partner_Detail_Id,
          City_Id: notification.CITY_ID,
          Partner_Service_City_Id: notification.PARTNER_SERVICE_CITY_ID
        }
        navigate('/app/view-partnercitydeal');
        dispatch(getPartnerServiceCityDealAction(formdata, callBackPartnerServiceCityDealData))
      }
    }
    else if (notification.Type === "Service City Coupon") {
      if (notification.Partner_Service_City_Coupon_Id) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Service_City_Coupon_Id: notification.Partner_Service_City_Coupon_Id,
          Partner_Details_Id: notification.Partner_Detail_Id,
          City_Id: notification.CITY_ID,
          Partner_Service_City_Id: notification.PARTNER_SERVICE_CITY_ID
        }
        navigate('/app/view-partnercitycoupon');
        dispatch(getPartnerServiceCityCouponAction(formdata, callBackPartnerServiceCityCouponData))
      }
    }
    else if (notification.Type === "Partner Service City Coupons") {
      if (notification.Partner_Service_City_Coupon_Id) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Service_City_Coupon_Id: notification.Partner_Service_City_Coupon_Id,
          Partner_Details_Id: notification.Partner_Detail_Id,
          City_Id: notification.CITY_ID,
          Partner_Service_City_Id: notification.PARTNER_SERVICE_CITY_ID
        }
        navigate('/app/view-partnercitycoupon');
        dispatch(getPartnerServiceCityCouponAction(formdata, callBackPartnerServiceCityCouponData))
      }
    }
    else if (notification.Type === "Service City Products") {
      if (notification.Partner_Service_City_Products_Id
      ) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Service_City_Products_Id: notification.Partner_Service_City_Products_Id,
          Partner_Details_Id: notification.Partner_Detail_Id,
          City_Id: notification.City_Id,
          Partner_Service_City_Id: notification.Partner_Service_City_Id,
          search_by_filter: "",
          search: "",
        }
        navigate('/app/view-partnercityproduct');
        dispatch(getPartnerServiceCityProductAction(formdata, callBackPartnerServiceCityProductData))
      }
    }
    else if (notification.Type === "Partner Service Product") {
      if (notification.PARTNER_SERVICE_CITY_PRODUCTS_ID) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Service_City_Products_Id: notification.PARTNER_SERVICE_CITY_PRODUCTS_ID,
          Partner_Details_Id: notification.Partner_Detail_Id,
          City_Id: notification.CITY_ID,
          Partner_Service_City_Id: notification.PARTNER_SERVICE_CITY_ID
        }
        navigate('/app/view-partnercityproduct');
        dispatch(getPartnerServiceCityProductAction(formdata, callBackPartnerServiceCityProductData))
      }
    }
    else if (notification.Type === "Partner Service City Product") {
      if (notification.PARTNER_SERVICE_CITY_PRODUCTS_ID) {
        let formdata = {
          Records_Filter: "FILTER",
          Partner_Service_City_Products_Id: notification.PARTNER_SERVICE_CITY_PRODUCTS_ID,
          Partner_Details_Id: notification.Partner_Detail_Id,
          City_Id: notification.CITY_ID,
          Partner_Service_City_Id: notification.PARTNER_SERVICE_CITY_ID
        }
        navigate('/app/view-partnercityproduct');
        dispatch(getPartnerServiceCityProductAction(formdata, callBackPartnerServiceCityProductData))
      }
    }
    else if (notification.Type === "Partner SignUp") {
      let formdata = {
        Records_Filter: "FILTER",
        Partner_Details_Id: notification.Partner_Detail_Id,
        Status: "In-Active"
      }
      navigate('/app/view-partnerapproval');
      dispatch(getPendingPartnerAction(formdata, callBackPendingPartnerData))
    }
    else if (notification.Type === "Partner_SignUp") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Partner_Details_Id: notification.Partner_Detail_Id,
        // Status: "In-Active"
      }
      navigate('/app/view-partnerapproval');
      dispatch(getPendingPartnerAction(formdata, callBackPendingPartnerData))
    }
    else if (notification.Type === "Company Profile") {
      let formdata = {
        // Records_Filter: "FILTER",
        // Partner_Details_Id: notification.Partner_Detail_Id
      }
      navigate('/app/mycompanyprofile');
      dispatch(getMyCompanyProfileAction(formdata, callBackCompanyProfileData))
    }
    else if (notification.Type === "Bank Details") {
      let formdata = {
        Records_Filter: "FILTER",
        Partner_Details_Id: notification.Partner_Detail_Id,
        Parnter_Bank_Detail_Id: notification.Partner_Bank_Detail_Id
      }
      navigate('/app/partnerbank-details');
      dispatch(getBankdetailsAction(formdata, callBackpartnerbank))
    }
    else if (notification.Type === "Partner_Bank_Details_Update") {
      let formdata = {
        Records_Filter: "FILTER",
        Partner_Details_Id: notification.Partner_Detail_Id,
        Parnter_Bank_Detail_Id: notification.Partner_Bank_Detail_Id
      }
      navigate('/app/partnerbank-details');
      dispatch(getBankdetailsAction(formdata, callBackpartnerbank))
    }
    else if (notification.Type === "Partner_Bank_Details_Create") {
      let formdata = {
        Records_Filter: "FILTER",
        Partner_Details_Id: notification.Partner_Detail_Id,
        Parnter_Bank_Detail_Id: notification.Partner_Bank_Detail_Id
      }
      navigate('/app/partnerbank-details');
      dispatch(getBankdetailsAction(formdata, callBackpartnerbank))
    }
    else if (notification.Type === "Ticket Comment") {
      let formdata = {
        Records_Filter: "FILTER",
        Ticket_Comment_id: notification.TICKET_COMMENT_ID
      }
      navigate('/app/user-comments-details');
      dispatch(getUserCommentAction(formdata, callBackUserCommentData))
    }
    else if (notification.Type === "User Ticket Comment") {
      let formdata = {
        Records_Filter: "FILTER",
        Ticket_Comment_id: notification.TICKET_COMMENT_ID
      }
      navigate('/app/user-comments-details');
      dispatch(getUserCommentAction(formdata, callBackUserCommentData))
    }
    else if (notification.Type === "Delivery Ticket Comment") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Del_Ticket_Comment_Id: notification.Del_Ticket_Comment_Id
      }
      navigate('/app/delivery-comments-details');
      dispatch(getDeliveryCommentAction(formdata, callBackDeliveryCommentData))
    }
    else if (notification.Type === "User Wallet History") {
      if (notification.Wallet_History_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Wallet_Id: notification.Wallet_Id,
          Wallet_History_Id: notification.Wallet_History_Id
        }
        navigate('/app/view-wallet-history');
        dispatch(getWalletHistoryAction(formdata, callBackWalletHistoryData))
      }
    }
    else if (notification.Type === "User Withdraw") {
      if (notification.Wallet_History_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Wallet_Id: notification.Wallet_Id,
          Wallet_History_Id: notification.Wallet_History_Id
        }
        navigate('/app/view-wallet-history');
        dispatch(getWalletHistoryAction(formdata, callBackWalletHistoryData))
      }
    }
    else if (notification.Type === "Partner Wallet History") {
      if (notification.Wallet_History_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Wallet_Id: notification.Wallet_Id,
          Wallet_History_Id: notification.Wallet_History_Id
        }
        navigate('/app/view-partnerwallethistory');
        dispatch(getPartnerWalletHistoryAction(formdata, callBackPartnerWalletHistoryData))
      }
    }
    else if (notification.Type === "Delivery Wallet History") {
      if (notification.Wallet_History_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Wallet_Id: notification.Wallet_Id,
          Wallet_History_Id: notification.Wallet_History_Id
        }
        navigate('/app/view-delivery-wallethistory');
        dispatch(getDeliveryWalletHistoryAction(formdata, callBackDeliveryWalletHistoryData))
      }
    }
    else if (notification.Type === "Delivery Withdrawl") {
      if (notification.Wallet_History_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Wallet_Id: notification.Wallet_Id,
          Wallet_History_Id: notification.Wallet_History_Id
        }
        navigate('/app/view-delivery-wallethistory');
        dispatch(getDeliveryWalletHistoryAction(formdata, callBackDeliveryWalletHistoryData))
      }
    }

    else if (notification.Type === "Del Vehicle Details") {
      if (notification.DEL_VEHICLE_DETAIL_ID) {
        let formdata = {
          Records_Filter: "FILTER",
          Del_Vehicle_Detail_Id: notification.DEL_VEHICLE_DETAIL_ID
        }
        navigate('/app/view-vehicledetails');
        dispatch(getDeliveryVehicleAction(formdata, callBackDeliveryVehicleData))
      }
    }
    else if (notification.Type === "Delivery Vehicle Details") {
      if (notification.Del_Vehicle_Detail_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Del_Vehicle_Detail_Id: notification.Del_Vehicle_Detail_Id
        }
        navigate('/app/view-vehicledetails');
        dispatch(getDeliveryVehicleAction(formdata, callBackDeliveryVehicleData))
      }
    }
    else if (notification.Type === "Super Admin Del Vehicle Details") {
      if (notification.Del_Vehicle_Detail_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Del_Vehicle_Detail_Id: notification.Del_Vehicle_Detail_Id
        }
        navigate('/app/view-vehicledetails');
        dispatch(getDeliveryVehicleAction(formdata, callBackDeliveryVehicleData))
      }
    }
    else if (notification.Type === "Super_Admin_Delivery_Vehicle_Details") {
      if (notification.Del_Vehicle_Detail_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Del_Vehicle_Detail_Id: notification.Del_Vehicle_Detail_Id
        }
        navigate('/app/view-vehicledetails');
        dispatch(getDeliveryVehicleAction(formdata, callBackDeliveryVehicleData))
      }
    }
    else if (notification.Type === "Partner Approval") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Partner_Details_Id: notification.Partner_Detail_Id
      }
      navigate('/app/partner-management-history');
      dispatch(getSinglePartnerAction(formdata, callBackPartnerData))
    }
    else if (notification.Type === "Super Admin City List") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        City_Id: notification.CITY_ID
      }
      navigate('/app/view-citydetails');
      dispatch(getCityAction(formdata, callBackCityData))
    }
    else if (notification.Type === "Super Admin Partner Delivery Person") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Del_User_Detail_Id: notification.Del_User_Detail_Id,
        Partner_Id: notification.Partner_Detail_Id,
        Del_user_email_id: notification.Del_User_Email_Id
      }
      navigate('/app/view-partner-deliveryagent');
      dispatch(getPartnerDeliveryAgentAction(formdata, callBackPartnerDeliveryAgentData))
    }
    else if (notification.Type === "Super_Admin_Partner_Delivery_Person") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Del_User_Detail_Id: notification.Del_User_Detail_Id,
        Partner_Id: notification.Partner_Detail_Id,
        Del_user_email_id: notification.Del_User_Email_Id
      }
      navigate('/app/view-partner-deliveryagent');
      dispatch(getPartnerDeliveryAgentAction(formdata, callBackPartnerDeliveryAgentData))
    }
    else if (notification.Type === "Delivery Partner Registration") {
      let formdata = {
        Records_Filter: "FILTER",
        Del_User_Detail_Id: notification.DEL_USER_DETAIL_ID
      }
      navigate('/app/view-partner-deliveryagent');
      dispatch(getPartnerDeliveryAgentAction(formdata, callBackPartnerDeliveryAgentData))
    }
    else if (notification.Type === "Delivery_Partner_Registration") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Del_user_email_id: notification.Del_User_Email_Id
      }
      navigate('/app/view-partner-deliveryagent');
      dispatch(getPartnerDeliveryAgentAction(formdata, callBackPartnerDeliveryAgentData))
    }
    else if (notification.Type === "Partner Delivery Vehicle Details") {
      if (notification.Del_Vehicle_Detail_Id) {
        let formdata = {
          search_by_filter: "",
          search: "",
          Records_Filter: "FILTER",
          Del_Vehicle_Detail_Id: notification.Del_Vehicle_Detail_Id,
          Partner_Id: notification.Partner_Detail_Id,
        }
        navigate('/app/view-partnervehicledetails');
        dispatch(getPartnerVehicleDataAction(formdata, callBackPartnerVehicleData))
      }
    }
    else if (notification.Type === "Partner Service City & Location") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Partner_Service_City_Id: notification.PARTNER_SERVICE_CITY_ID
      }
      navigate('/app/view-partnerservicecity');
      dispatch(getServiceCityPartnerAction(formdata, callBackServiceCityPartnerData))
    }
    else if (notification.Type === "Partner Service City") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Partner_Service_City_Id: notification.PARTNER_SERVICE_CITY_ID
      }
      navigate('/app/view-partnerservicecity');
      dispatch(getServiceCityPartnerAction(formdata, callBackServiceCityPartnerData))
    }
    else if (notification.Type === "User Signup") {
      let formdata = {
        Records_Filter: "FILTER",
        Email_Id: notification.User_Email_Id
      }
      navigate('/app/view-user-managment');
      dispatch(getUsersignupAction(formdata, callBacksignupData))
    }
    else if (notification.Type === "Delivery Signup") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Del_User_Email_Id: notification.Del_User_Email_Id,
      }
      navigate('/app/view-pending-delivery-agent-details');
      dispatch(getDeliveryAgentRegApprovalAction(formdata, callBackDeliveryAgentRegApprovalData))
    }
    else if (notification.Type === "Partner Products") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Product_Id: notification.Product_Id
      }
      navigate('/app/view-productapproval');
      dispatch(getProductAction(formdata, callBackProductsData))
    }
    else if (notification.Type === "Shop by Location In City") {
      if (notification.SHOP_BY_LOCATION_CITY_ID) {
        let formdata = {
          Records_Filter: "FILTER",
          Shop_By_Location_In_City_Id: notification.SHOP_BY_LOCATION_CITY_ID
        }
        navigate('/app/view-city-partner-location');
        dispatch(getPartnerServiceCityAction(formdata, callBackPartnerServiceCityData))
      }
    }
    else if (notification.Type === "Shop By Location City") {
      if (notification.SHOP_BY_LOCATION_CITY_ID) {
        let formdata = {
          Records_Filter: "FILTER",
          Shop_By_Location_In_City_Id: notification.SHOP_BY_LOCATION_CITY_ID
        }
        navigate('/app/view-city-partner-location');
        dispatch(getPartnerServiceCityAction(formdata, callBackPartnerServiceCityData))
      }
    }
    else if (notification.Type === "User Product Bidding Request") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Products_Bidding_Id: notification.User_Products_Bidding_Id
      }
      navigate('/app/view-user-bidding-products');
      dispatch(getBiddingUserProductAction(formdata, callBackBiddingProductsData))
    }
    else if (notification.Type === "User Products Bidding") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Products_Bidding_Id: notification.User_Products_Bidding_Id
      }
      navigate('/app/view-user-bidding-products');
      dispatch(getBiddingUserProductAction(formdata, callBackBiddingProductsData))
    }
    else if (notification.Type === "User Bidding Ride Request") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Ride_Bidding_Id: notification.User_Ride_Bidding_Id
      }
      navigate('/app/view-user-ride-bidding');
      dispatch(getBiddingUserRideAction(formdata, callBackBiddingRidesData))
    }
    else if (notification.Type === "User Ride Bidding Update") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Ride_Bidding_Id: notification.User_Ride_Bidding_Id
      }
      navigate('/app/view-user-ride-bidding');
      dispatch(getBiddingUserRideAction(formdata, callBackBiddingRidesData))
    }
    else if (notification.Type === "User Ride Bidding update") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Ride_Bidding_Id: notification.User_Ride_Bidding_Id
      }
      navigate('/app/view-user-ride-bidding');
      dispatch(getBiddingUserRideAction(formdata, callBackBiddingRidesData))
    }
    else if (notification.Type === "User_Bidding_Ride_Request") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Ride_Bidding_Id: notification.User_Ride_Bidding_Id
      }
      navigate('/app/view-user-ride-bidding');
      dispatch(getBiddingUserRideAction(formdata, callBackBiddingRidesData))
    }
    else if (notification.Type === "User_Bidding_Ride_Create") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Ride_Bidding_Id: notification.User_Ride_Bidding_Id
      }
      navigate('/app/view-user-ride-bidding');
      dispatch(getBiddingUserRideAction(formdata, callBackBiddingRidesData))
    }
    else if (notification.Type === "User Ride Bidding Create") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Ride_Bidding_Id: notification.User_Ride_Bidding_Id
      }
      navigate('/app/view-user-ride-bidding');
      dispatch(getBiddingUserRideAction(formdata, callBackBiddingRidesData))
    }
    else if (notification.Type === "User_Ride_Update") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Ride_Bidding_Id: notification.User_Ride_Bidding_Id
      }
      navigate('/app/view-user-ride-bidding');
      dispatch(getBiddingUserRideAction(formdata, callBackBiddingRidesData))
    }
    else if (notification.Type === "Partner_Bidding_Available_Service") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        Partner_Bidding_Available_Service_Id: notification.Partner_Bidding_Available_Services_Id
      }
      navigate('/app/view-partner-bidding-available-services');
      dispatch(getBiddingAvailServiceAction(formdata, callBackBiddingAvailService))
    }
    else if (notification.Type === "Partner Available Product") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Partner_Bidding_Available_Products_Id: notification.Partner_Bidding_Available_Product_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/partner-bidding-available-products-view');
      dispatch(getBiddingAvailProductAction(formdata, callBackBiddingAvailProduct))
    }
    else if (notification.Type === "SuperAdmin Bidding Products") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Partner_Bidding_Available_Products_Id: notification.Partner_Bidding_Available_Product_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/partner-bidding-available-products-view');
      dispatch(getBiddingAvailProductAction(formdata, callBackBiddingAvailProduct))
    }
    else if (notification.Type === "Bidding_Ride_Vehicle_Brands") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Bidding_Ride_Vehicle_Brand_Id: notification.Bidding_Ride_Vehicle_Brand_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/bidding-vehicle-brand-view');
      dispatch(getBiddingVehicleBrandAction(formdata, callBackBiddingVehicleBrand))
    }
    else if (notification.Type === "Partner_Available_Product_Create") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Partner_Bidding_Available_Products_Id: notification.Partner_Bidding_Available_Product_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/partner-bidding-available-products-view');
      dispatch(getBiddingAvailProductAction(formdata, callBackBiddingAvailProduct))
    }
    else if (notification.Type === "B2B_Bidding_Product") {
      let formdata = {
        search_by_filter: "",
        search: "",
        B2B_User_Products_Bidding_Id: notification.B2b_Bidding_Products_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/view-b2b-user-products-bidding');
      dispatch(getB2BBiddingUserProductsAction(formdata, callBackB2BBiddingProducts))
    }
    else if (notification.Type === "B2B Partner_Bidding_Products_Update") {
      let formdata = {
        search_by_filter: "",
        search: "",
        B2B_User_Products_Bidding_Id: notification.B2b_Bidding_Products_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/view-b2b-user-products-bidding');
      dispatch(getB2BBiddingUserProductsAction(formdata, callBackB2BBiddingProducts))
    }
    else if (notification.Type === "B2B_Partner_Bidding_Quote") {
      let formdata = {
        search_by_filter: "",
        search: "",
        B2B_Partner_Bidding_Quote_Id: notification.B2b_Partner_Bidding_Quote_Id,
        // B2B_User_Products_Bidding_Id: notification.B2b_Bidding_Products_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/view-b2b-user-product-bidding-quote');
      dispatch(getB2BBiddingProductQuoteAction(formdata, callBackB2BBiddingProductQuote))
    }
    else if (notification.Type === "Super Admin B2B Partner Bidding Quotes") {
      let formdata = {
        search_by_filter: "",
        search: "",
        B2B_Partner_Bidding_Quote_Id: notification.B2b_Partner_Bidding_Quote_Id,
        B2B_User_Products_Bidding_Id: notification.B2b_Bidding_Products_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/view-b2b-user-product-bidding-quote');
      dispatch(getB2BBiddingProductQuoteAction(formdata, callBackB2BBiddingProductQuote))
    }
    else if (notification.Type === "Partner_Bidding_Quotation_Created") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Partner_Bidding_Quote_Id: notification.Partner_Bidding_Product_Quote_Id,
        User_Products_Bidding_Id: notification.User_Products_Bidding_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/partner-bidding-quote-view');
      dispatch(getBiddingProductsQuoteAction(formdata, callBackBiddingProductsQuote))
    }
    else if (notification.Type === "Partner Bidding Quotations Update") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Partner_Bidding_Quote_Id: notification.Partner_Bidding_Product_Quote_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/partner-bidding-quote-view');
      dispatch(getBiddingProductsQuoteAction(formdata, callBackBiddingProductsQuote))
    }
    else if (notification.Type === "User_Service_Request") {
      let formdata = {
        search_by_filter: "",
        search: "",
        User_Service_Bidding_Id: notification.User_Service_Bidding_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/partner-bidding-quote-view');
      dispatch(getBiddingUserServiceAction(formdata, callBackBiddingServiceData))
    }
    else if (notification.Type === "Bidding_Products_Quotation_Update") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Partner_Bidding_Quote_Id: notification.Partner_Bidding_Product_Quote_Id,
        User_Products_Bidding_Id: notification.User_Products_Bidding_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/partner-bidding-quote-view');
      dispatch(getBiddingProductsQuoteAction(formdata, callBackBiddingProductsQuote))
    }
    else if (notification.Type === "Partner_Bidding_Service_Quotes_Created") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Partner_Bidding_Service_Quote_Id: notification.Partner_Bidding_Services_Quote_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/view-partner-bidding-service-quotes');
      dispatch(getBiddingServiceQuoteAction(formdata, callBackBiddingServiceQuote))
    }
    else if (notification.Type === "Partner_Bidding_Service_Quote") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Partner_Bidding_Service_Quote_Id: notification.Partner_Bidding_Services_Quote_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/view-partner-bidding-service-quotes');
      dispatch(getBiddingServiceQuoteAction(formdata, callBackBiddingServiceQuote))
    }
    else if (notification.Type === "User_Product_Bidding_Request_Craete") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Records_Filter: "FILTER",
        User_Products_Bidding_Id: notification.User_Products_Bidding_Id
      }
      navigate('/app/view-user-bidding-products');
      dispatch(getBiddingUserProductAction(formdata, callBackBiddingProductsData))
    }
    else if (notification.Type === "Bidding_Ride_Quote") {
      let formdata = {
        search_by_filter: "",
        search: "",
        Delivery_Bidding_Quote_Id: notification.Bidding_Ride_Quote_Id,
        User_Ride_Bidding_Id: notification.User_Ride_Bidding_Id,
        Records_Filter: "FILTER"
      }
      navigate('/app/view-delivery-bidding-quote');
      dispatch(getBiddingRideQuoteAction(formdata, callBackBiddingRideQuote))
    }
  }

  const [state, setState] = React.useState({
    right: false, [anchor]: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    pouseAudioOrder()
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350 }}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Tooltip title="Close">
          <IconButton >
            <CloseIcon style={{ width: 15, height: 15 }} />
          </IconButton>
        </Tooltip>
        <Typography className={classes.content} variant="h4" gutterBottom>
          Notifications*
        </Typography>
        <Divider />
        {isEmpty(notifications) &&
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', paddingBottom: '15px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
            No notifications found!
          </div>
        }
        {!isEmpty(notifications) && notifications.results?.map((notify, index) => (
          <div style={{ fontSize: 12, color: "#418008" }} key={index}>
            <Divider />
            <div style={{ backgroundColor: notify.Is_Super_Admin_Read == "0" ? "#d3d3d3" : "ffff" }}>
              <ListItem button key={notify.Notification_Id}>
                <div onClick={(e) => { notificationData(notify) }} >
                  <div className={classes.text}>
                    <div>
                      <div style={{ fontSize: 12, color: "#418008", fontWeight: "bold", marginBottom: "10px" }}>  {notify.Subject} </div>
                      <div style={{ fontSize: "14px", marginTop: "5px", lineHeight: "normal", color: "#000000" }}>{notify.Description}</div>
                      <div className={classes.time}>{(notify.Time)}</div>
                    </div>
                  </div>
                </div>

              </ListItem>
            </div>
            <Divider />
          </div>
        ))}
      </List>
    </Box>
  );
  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <audio ref={audioPlayer} src={NotificationSound} />
      <audio ref={audioPlayerorder} src={NotificationOrderSound} />
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        {/* <Hidden mdDown> */}
        <IconButton color="inherit" onClick={toggleDrawer(anchor, true)}>
          <NotificationsIcon />
          {notifications.notification_pending_read_count === 0 || notifications.notification_pending_read_count === "" ? null : <Badge overlap="rectangular">
            <div style={{
              backgroundColor: notifications.notification_pending_read_count ? "red" : "#27B6CC",
              width: "18px",
              height: "18px",
              borderRadius: "50%",
              fontSize: "10px",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              top: "-30px",
              marginTop: "-20px",
              left: '-5px',
              marginLeft: '-10px',
            }} onClick={notificationData}>{notifications.notification_pending_read_count === "0" ? null : notifications.notification_pending_read_count}</div>
          </Badge>}
        </IconButton>
        <IconButton color="inherit" onClick={Logout} >
          <PowerSettingsNewIcon />
        </IconButton>
        {/* </Hidden> */}
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <SwipeableDrawer
        anchor={'right'}
        open={state[anchor]}
        onClose={() => toggleDrawer(anchor, false)}
        onOpen={() => toggleDrawer(anchor, true)}
      // swipeAreaWidth={drawerBleeding}
      >
        {list(anchor)}
      </SwipeableDrawer>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};


export default TopBar;