import { Card, CardContent, Grid } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import { Transaction_Orders_Get } from 'src/store/actions/node-actions/nodepagination';
import { Box, Button, Stack, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, TextField, Tooltip, IconButton, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
// import InfoIcon from '@mui/icons-material/Info';
import { CouponUserCashback, couponUtilizedDetails, UserCashback } from 'src/store/actions/ordersAction';

const PartnerCupnUtlDetails = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;
  const [open, setOpen] = useState(false);
  const [transactOrders, setTransactOrders] = useState([]);
  const [couponDetails, setCouponDetails] = useState([]);
  const [cnclOrdrCashback, setcnclOrdrCashback] = useState(false);
  const [inprgsOrdrCashback, setinprgsOrdrCashback] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const orderId = state?.Coupon_Utilisation_Id;
  const partnerId = state?.Partner_Details_Id;
  const cupnId = state?.Coupon_Utilisation_Id;

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //dispatch actions
  // const CouponUtilizeDetails_Call = async () => {
  //   try {
  //     const formData = {
  //       "Coupon_Utilisation_Id": cupnId,
  //       "Partner_Details_Id": partnerId
  //     };
  //     const response = await couponUtilizedDetails(formData);
  //     if (response) {
  //       setCouponDetails(response);
  //     } else {
  //       setCouponDetails([]);
  //     }
  //   } catch (error) {
  //     setCouponDetails([]); // Handle error by setting to empty
  //   }
  // };

  const TransactOrders_Call = async () => {
    try {
      const formData = {
        Coupon_Utilisation_Id: state?.Coupon_Utilisation_Id,
      };
      const response = await dispatch(Transaction_Orders_Get(formData));
      if (response?.status) {
        setTransactOrders(response.data);
      } else {
        setTransactOrders([]);
      }
    } catch (error) {
      setTransactOrders([]);
    }
  };

  // Call the function inside useEffect on component mount
  useEffect(() => {
    // CouponUtilizeDetails_Call();
    TransactOrders_Call();
  }, []);

  const isBeforeReturnDate = (Created_Date, Return_Days) => {
    // if (!Created_Date || !Return_Days) return false; // Handle invalid data  
    const date = new Date(Created_Date?.Date); // Convert to Date object
    const compareDate = new Date(date.setDate(date.getDate() + Return_Days));
    const currentDate = new Date();
    // console.log(currentDate, compareDate, Created_Date, Return_Days);
    return currentDate >= compareDate;
  };

  const cashbackStatusCheck = useMemo(() => {
    if (transactOrders.length > 0) {
      // const delOrders = transactOrders.filter(value => value.Order_Status === "DELIVERED").length
      const delOrders = transactOrders
        .filter(order => order.Order_Status === "DELIVERED")
        .some(order => isBeforeReturnDate(JSON.parse(order?.Order_Status_Dates_Json)?.DELIVERED, order?.Return_Days));
      const returnDeclineFulfillOrders = transactOrders.filter(value => value.Return_Status === "DECLINED").length && transactOrders.filter(value => value.Is_Order_Fulfilled === 1).length
      const partialRefund = transactOrders.filter(value => value.Order_Status === "CANCEL").length
      const partlRefunded = transactOrders.filter(value => value.Return_Status === "REACHED").length
      const partiallyRefund = transactOrders.filter(value => value.Exchange_Status === "REACHED").length
      const totalScenarios = delOrders + returnDeclineFulfillOrders + partialRefund + partlRefunded + partiallyRefund
      return totalScenarios === transactOrders.length
    } else {
      return false
    }
  }, [transactOrders]);

  const cnclDclnStatusCheck = useMemo(() => {
    if (transactOrders.length > 0) {
      const cnclDclnOrders = transactOrders.filter(value => value.Order_Status === "CANCEL").length
      const dclnOrders = transactOrders.filter(value => value.Order_Status === "DECLINED").length
      const Scenarios = cnclDclnOrders + dclnOrders
      return Scenarios === transactOrders.length
    } else {
      return false
    }
  }, [transactOrders]);

  const inprgrsStatusCheck = useMemo(() => {
    if (transactOrders.length > 0) {
      const acptdOrders = transactOrders.filter(value => value.Order_Status === "ACCEPTED").length
      const rdyforshpngOrders = transactOrders.filter(value => value.Order_Status === "READY_FOR_SHIPPING").length
      const shpdOrders = transactOrders.filter(value => value.Order_Status === "SHIPPED").length
      const outfordlvryOrders = transactOrders.filter(value => value.Order_Status === "OUT_FOR_DELIVERY").length
      // const dlvrdOrders = transactOrders.filter(value => value.Order_Status === "DELIVERED").length && isBeforeReturnDate(transactOrders[0]?.Order_Status_Dates_Json?.DELIVERED, transactOrders[0]?.Return_Days)
      const dlvrdOrders = transactOrders
        .filter(order => order.Order_Status === "DELIVERED")
        .some(order => isBeforeReturnDate(JSON.parse(order?.Order_Status_Dates_Json)?.DELIVERED, order?.Return_Days));
      const Scenarios = acptdOrders + rdyforshpngOrders + shpdOrders + outfordlvryOrders + dlvrdOrders
      return Scenarios === transactOrders.length
    } else {
      return false
    }
  }, [transactOrders]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Check if the entered value is less than or equal to Orders.Cashback
    if (!isNaN(value) && Number(value) <= state?.Coupon_Cashback_Availabile_Amount) {
      setInputValue(value);
    } else if (value === "") {
      setInputValue("");
    }
  };

  const preventInvalidInput = (e) => {
    // Prevent entering negative signs and invalid characters
    if (e.key === '-' || e.key === 'e' || e.key === '+') {
      e.preventDefault();
    }
  };

  const handleConfirm = async (orderId) => {
    setLoading(true);
    const formData = {
      "Cashback_Amount": inputValue,
      "CancelOrdersCashbackApproved": cnclOrdrCashback,
      "InProgressOrdersCashbackApproved": inprgsOrdrCashback
    };
    const result = await dispatch(CouponUserCashback(orderId, formData));
    setLoading(false);
    if (result.data.code === "Success") {
      navigate('/app/partner-cupn-utlze')
    } else {
      toast(result.data.UI_Display_Message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      });
    } setOpen(false);
  };


  return (
    <Card>

      <CardContent>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <Grid container spacing={3}>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Coupon Utilisation Id
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Coupon_Utilisation_Id}
                </Typography>
              </Grid>

              {/* Transaction_Status */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Transaction Status
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Payment_Transaction_Status}
                </Typography>
              </Grid>



              {/* <Grid container alignItems="center" spacing={2} direction="row"> */}
              {/* <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Meepaisa User Cashback Amount
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {couponDetails.Meepaisa_User_Cashback_Amount}
                </Typography>
              </Grid> */}

              {/* </Grid> */}

              {/* <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Meepaisa User Cashback Status
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {couponDetails.Meepaisa_User_Cashback_Status}
                </Typography>
              </Grid> */}

              {/* Payment_Gateway_Reference_Number */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Transaction Id
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Transaction_Id}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Coupon Cashback Type
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Coupon_Cashback_Type}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Coupon Code
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Coupon_Code}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Coupon Discount
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Coupon_Discount}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Coupon User Cashback Amount
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Coupon_User_Cashback_Amount}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Coupon User Cashback Status
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {/* {state.Coupon_User_Cashback_Status} */}
                  {state.Coupon_User_Cashback_Status === 1 ? "SUCCESS" : "PENDING"}
                </Typography>
              </Grid>

              <Grid style={{ marginLeft: '2px' }} container alignItems="center" spacing={2} direction="row">

                <Grid item xs={5}>
                  <Typography
                    variant="h5"
                    component="h2"
                    gutterBottom
                    style={{ color: '#66788A' }}
                  >
                    Coupon Cashback Available Amount
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="h5"
                    component="h2"
                    gutterBottom
                    style={{ paddingLeft: '3rem' }}
                  >
                    {state.Coupon_Cashback_Availabile_Amount}
                  </Typography>
                </Grid>

                {state?.Coupon_User_Cashback_Status === 0 ? (
                  <>
                    {cashbackStatusCheck && (
                      <Grid item xs={2}>
                        <Button
                          onClick={handleClickOpen}
                          style={{
                            backgroundColor: '#27b6cc',
                            width: '170px',
                            height: '30px',
                            color: '#fff',
                          }}
                        >
                          User Settle
                        </Button>
                      </Grid>
                    )}
                  </>
                ) : null}

              </Grid>

            </Grid>
          </Grid>

          {/*  column 2 */}
          <Grid item md={6}>
            <Grid container spacing={3}>

              {/* Payment_Method */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Payment Method
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Payment_Type}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Created By
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Created_By}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Created Date
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Created_Date}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  User Detail Id
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.User_Details_Id}
                </Typography>
              </Grid>


              {/* Modified_By */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Modified By
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Modified_By}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Modified Date
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Modified_Date}
                </Typography>
              </Grid>

              {/* 
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Meepaisa Cashback Available Amount
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {couponDetails.Meepaisa_Cashback_Availabile_Amount}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Meepaisa Cashback Type
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {couponDetails.Meepaisa_Cashback_Type}
                </Typography>
              </Grid> */}

            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ padding: 1, marginTop: '10px' }}>
          <Grid container spacing={3}>
            {transactOrders.length > 0 ? (
              transactOrders.map((order) => (
                <Grid item xs={12} sm={6} md={4} key={order.Order_Id}>
                  <Card variant="outlined">
                    <CardContent>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="h6" gutterBottom>
                          Order ID: {order.Order_Id}
                        </Typography>
                      </Stack>
                      <Typography variant="h6" gutterBottom>
                        Order Status: {order.Order_Status}
                      </Typography>
                      <Typography variant="body1">
                        Product: {order.Product_Name}
                      </Typography>
                      <Typography variant="body2">
                        Quantity: {order.Quantity}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Order Value: {order.Total_Order_Value} /-
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="h6" color="text.secondary">
                No orders available
              </Typography>
            )}
          </Grid>
        </Box>
      </CardContent>

      <Dialog open={open}>
        <DialogTitle>Cashback for User</DialogTitle>
        <DialogContent>
          <DialogContentText> Are you sure to provide PARNER COUPON CASHBACK for the USER? </DialogContentText>

          <TextField
            autoFocus
            label="Enter Cashback"
            type="text"
            style={{ width: '200px', marginTop: '10px' }}
            value={inputValue}
            onChange={handleInputChange}
            // helperText={`Max cashback: ${couponDetails?.Coupon_Cashback_Availabile_Amount}`}
            helperText={
              <span style={{ color: 'red' }}>
                Max cashback: {state?.Coupon_Cashback_Availabile_Amount} /-
              </span>
            }
            inputProps={{ min: 1 }} // Prevents entering values less than 1 using arrows
            onKeyDown={(e) => preventInvalidInput(e)}
          />

          <Box display="flex" justifyContent="space-between" marginTop="10px">

            {cnclDclnStatusCheck && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cnclOrdrCashback}
                    onChange={(e) => setcnclOrdrCashback(e.target.checked)}
                  />
                }
                label="Cancel/Decline cashback approval"
              />)}

            {inprgrsStatusCheck &&
              (<FormControlLabel
                control={
                  <Checkbox
                    checked={inprgsOrdrCashback}
                    onChange={(e) => setinprgsOrdrCashback(e.target.checked)}
                  />
                }
                label="Inprogress cashback approval"
              />)}

          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ backgroundColor: 'gray', margin: "1%" }}>
            Cancel
          </Button>
          <Button disabled={inputValue < 1 && loading}
            onClick={() => handleConfirm(orderId)}
            style={{ backgroundColor: '#27B6CC', margin: "1%" }}>
            {/* Confirm */}
            {loading ? (
              <CircularProgress size={24} style={{ color: 'white' }} />
            ) : (
              <span style={{ color: 'white' }}>Confirm</span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default PartnerCupnUtlDetails;